import React from 'react'

import { equals } from 'ramda'

import { Flex, Header, Text } from '@driftt/dds'

import DeltaIcon from 'components/Reporting/DeltaIcon'
import { TypeFormats } from 'utils/formatUtils'

import { getDeltaAsPercent } from '../helpers'

export interface MetricTileProps {
  title: string
  currentTotal: number
  previousTotal: number
  drillDownComponent: React.ReactNode
}

export const MetricTile = ({
  title,
  currentTotal,
  previousTotal,
  drillDownComponent,
}: MetricTileProps) => {
  const delta = getDeltaAsPercent(currentTotal, previousTotal)
  const isEmpty = equals(currentTotal, 0) && equals(delta, 0)

  return (
    <Flex direction="column" alignItems="flex-start">
      <Text variant="subtitle2">{title}</Text>
      <Header variant="metric">
        {isEmpty ? '-' : TypeFormats['FIXED'](currentTotal)}
      </Header>
      {!isEmpty && (
        <Flex alignItems="center">
          <DeltaIcon delta={delta} />
          <Text variant="metadata">{TypeFormats['PERCENT'](delta)}</Text>
        </Flex>
      )}
      {!isEmpty && drillDownComponent}
    </Flex>
  )
}
