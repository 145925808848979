import React from 'react'

import { gte } from 'ramda'

import { Icon } from '@driftt/dds'
import { faTriangle } from '@driftt/dds.icons/dist/solid/faTriangle'

const DeltaIcon = ({ delta }) => (
  <>
    {gte(delta, 0) ? (
      <Icon icon={faTriangle} size={'extra-small'} css={{ color: '$green6' }} />
    ) : (
      <Icon
        icon={faTriangle}
        size={'extra-small'}
        css={{ color: '$red8', rotate: '180deg' }}
      />
    )}
  </>
)

export default DeltaIcon
