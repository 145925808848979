import React, { useContext, useState } from 'react'

import {
  type CubeQuery,
  CONVERSATION_FACTS_DIMENSIONS,
  useTrackDrillDownModalView,
} from 'cube'
import { PointClickEventObject } from 'highcharts'
import moment, { Moment } from 'moment'

import { Flex, Link, SelectionTile, SimpleGrid } from '@driftt/dds'
import {
  ChartSeriesData,
  CubeQueryBuilder,
  CubeQueryStringAdapter,
  CustomSeriesLineOption,
  getStartOfSeriesTimestamp,
  LineChart,
} from '@driftt/dds.reports'

import { DashboardMetricsCard, Loading, MetricsError } from 'components'
import config from 'config/index'
import { CONVO_DETAIL_REPORT_PATH } from 'constants/reportConstants'
import DateRangeContext from 'contexts/dateRange'
import { formatSeriesData } from 'utils/Cube'

import {
  DRIFT_OVERVIEW_METRIC_TYPES,
  DRIFT_OVERVIEW_METRIC_TYPES_LABELS,
  MetricTypeToDrillDownQuery,
  OVERVIEW_METRICS_HEADER_TOOLTIP,
} from './constants'
import { getMeasureTotal } from './helpers'
import useDriftOverviewMetrics from './hooks/useDriftOverviewMetrics'
import { MetricTile } from './MetricTile'

export type OverviewMetricType =
  typeof DRIFT_OVERVIEW_METRIC_TYPES[keyof typeof DRIFT_OVERVIEW_METRIC_TYPES]

export const DriftOverviewMetrics = () => {
  const { timePeriod, legendText } = useContext(DateRangeContext)

  const [selectedMetric, setSelectedMetric] = useState<OverviewMetricType>(
    DRIFT_OVERVIEW_METRIC_TYPES.CONVERSATIONS
  )

  const { trackViewEvent } = useTrackDrillDownModalView(
    'DashboardDriftOverviewMetrics'
  )

  const {
    isLoading,
    isError,
    data: overviewData,
  } = useDriftOverviewMetrics({ timePeriod })

  // If there are no errors or loading, we can safely assume that the data is not null
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dataByDayCurrent = overviewData[selectedMetric].byDay.current!
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dataByDayPrevious = overviewData[selectedMetric].byDay.previous!

  const seriesDataCurrent = formatSeriesData(
    dataByDayCurrent
  ) as ChartSeriesData
  const seriesDataPrevious = formatSeriesData(
    dataByDayPrevious
  ) as ChartSeriesData

  const pointStart = getStartOfSeriesTimestamp(seriesDataCurrent)

  /**
   * Format drill down URL to conversation detail report
   * @param {OverviewMetricType} metricType
   * @returns {string}
   */
  const formatDrillDownURL = (metricType: OverviewMetricType) => {
    const drillDownQuery = formatDrillDownQuery(
      timePeriod.startDate,
      timePeriod.endDate,
      MetricTypeToDrillDownQuery[metricType],
      metricType
    )
    const drillDownQueryString =
      CubeQueryStringAdapter.toQueryString(drillDownQuery)
    return `${config.APP_URL}${CONVO_DETAIL_REPORT_PATH}?${drillDownQueryString}`
  }

  const formatDrillDownQuery = (
    startDate: Moment,
    endDate: Moment,
    cubeQuery: CubeQuery,
    metricType: OverviewMetricType
  ) =>
    new CubeQueryBuilder({
      query: cubeQuery,
    })
      .withName(`OverviewDrillDownQuery-${metricType}`)
      .withinDateRange(
        CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
        startDate.toDate(),
        endDate.toDate()
      )
      .withDimensions([CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_STATUS])
      .build()

  const handlePointClick = (evt: PointClickEventObject) => {
    const { name: pointDate, y: totalCount } = evt.point
    const startDate = moment(pointDate).startOf('D')
    const endDate = moment(pointDate).endOf('D')

    if (totalCount === 0 || totalCount === undefined) {
      return
    }

    const drillDownQuery = formatDrillDownQuery(
      startDate,
      endDate,
      MetricTypeToDrillDownQuery[selectedMetric],
      selectedMetric
    )

    trackViewEvent({ totalRecords: totalCount })

    // Drill down to conversation detail report
    const drillDownQueryString =
      CubeQueryStringAdapter.toQueryString(drillDownQuery)
    const drillDownURL = `${config.APP_URL}${CONVO_DETAIL_REPORT_PATH}?${drillDownQueryString}`
    window.open(drillDownURL, '_blank')
  }

  return (
    <DashboardMetricsCard
      header={'Drift overview'}
      headerTooltip={OVERVIEW_METRICS_HEADER_TOOLTIP}
    >
      {isLoading && <Loading />}
      {isError && <MetricsError />}
      {!isLoading && !isError && (
        <Flex direction="column" gap={3} fullHeight>
          <SelectionTile.Group
            as={SimpleGrid}
            gap={2}
            columns={3}
            defaultValue={selectedMetric}
            onChange={(metricType) =>
              setSelectedMetric(metricType as OverviewMetricType)
            }
            fullWidth
          >
            {(
              Object.keys(DRIFT_OVERVIEW_METRIC_TYPES) as Array<
                keyof typeof DRIFT_OVERVIEW_METRIC_TYPES
              >
            ).map((metricType) => (
              <SelectionTile.Item key={metricType} id={metricType}>
                <MetricTile
                  title={DRIFT_OVERVIEW_METRIC_TYPES_LABELS[metricType]}
                  currentTotal={getMeasureTotal(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    overviewData[metricType].total.current!
                  )}
                  previousTotal={getMeasureTotal(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    overviewData[metricType].total.previous!
                  )}
                  drillDownComponent={
                    <Link
                      variant="inline"
                      target="__blank"
                      href={formatDrillDownURL(metricType)}
                    >
                      View data
                    </Link>
                  }
                />
              </SelectionTile.Item>
            ))}
          </SelectionTile.Group>
          <Flex fullHeight fullWidth>
            <LineChart
              onPointClick={handlePointClick}
              series={[
                {
                  // Spreading this array to prevent a weird highcharts mutation issue
                  data: [...seriesDataCurrent],
                  pointStart: pointStart,
                  label: legendText.series,
                } as CustomSeriesLineOption,
                {
                  // Spreading this array to prevent a weird highcharts mutation issue
                  data: [...seriesDataPrevious],
                  label: legendText.previousSeries,
                  pointStart: pointStart,
                } as CustomSeriesLineOption,
              ]}
              isLoading={false}
              hideTooltipHeader
              tooltipYValueFormat="RAW_HUMANIZED"
            />
          </Flex>
        </Flex>
      )}
    </DashboardMetricsCard>
  )
}
