import React from 'react'

import { Flex, Header, Paper, styled, Text } from '@driftt/dds'

type TableWrapperProps = {
  title: string
  description?: string
  children: React.ReactNode
}

export const StyledHeader = styled(Flex, {
  paddingTop: '$3',
  paddingLeft: '$3',
  paddingRight: '$3',
  paddingBottom: '$1',
})

export const TableWrapper = ({
  title,
  description,
  children,
}: TableWrapperProps) => {
  return (
    <Paper elevation={2} as={Flex} direction="column" alignItems={'stretch'}>
      <StyledHeader direction="column" gap={'half'}>
        <Header variant={'h3'}>{title}</Header>
        {description ? <Text variant={'metadata'}>{description}</Text> : null}
      </StyledHeader>
      {children}
    </Paper>
  )
}
