import { recieveSchedulingStatus } from 'actions/Scheduling'
import { receiveGoogleScopes } from 'actions/Scopes'
import Cookie from 'js-cookie'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { hasOrgProperty } from 'selectors/Org'
import {
  profileHandle,
  profileHandleUrlSelector,
  userSettingsSelector,
} from 'selectors/User'

import { HasSeenDashboard } from 'constants/Cookies'
import { OnboardedDriftForSales } from 'constants/OrgProperties'
import { withDateRangeContext } from 'contexts/dateRange/dateRangeProvider'
import Home from 'layouts/Home'
import { hasGates, userHasFullDashboardAccess } from 'utils/accessUtils'

const mapStateToProps = (state) => {
  return {
    hasSeenDashboard: !!Cookie.get(HasSeenDashboard),
    isFreeUser: !hasGates('premium_drift'),
    hasFullDashboardAccess: userHasFullDashboardAccess(),
    isDriftForSalesOrg: hasOrgProperty(state, OnboardedDriftForSales),
    userHandle: profileHandle(state),
    userHandleUrl: profileHandleUrlSelector(state),
    orgName: state.org.name,
    userEmail: state.user.email,
    user: state.user,
    isBotUser: state.user.bot,
    isJITProvisioned: userSettingsSelector(state).IS_JIT_PROVISIONED_USER,
    orgId: state.user.orgId,
    bootstrapComplete: path(['config', 'bootstrapComplete'], state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onReceiveGoogleScopes: (scopes) => {
      dispatch(receiveGoogleScopes(scopes))
    },
    onRecieveSchedulingStatus: (status) => {
      dispatch(recieveSchedulingStatus(status))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDateRangeContext(Home))
