import React, { useContext } from 'react'

import DateRangeContext from 'contexts/dateRange'

const DateRangeProvider = ({ component: Component, ...props }) => {
  const { timePeriod, onChangeTimePeriod, legendText } =
    useContext(DateRangeContext)
  return (
    <Component
      {...props}
      timePeriod={timePeriod}
      onChangeTimePeriod={onChangeTimePeriod}
      legendText={legendText}
    />
  )
}

// eslint-disable-next-line react/display-name
export const withDateRangeContext = (Component) => (props) => {
  return <DateRangeProvider component={Component} {...props} />
}
