import React, { useState } from 'react'

import { trackDrillDownExportClick } from 'cube'
import { useTrackDrillDownModalView } from 'cube/hooks'

import { Button, Flex, Table, TableColumn, Text, UserAvatar } from '@driftt/dds'
import { type CubeQuery, DrillDownDataModal } from '@driftt/dds.reports'

import { MeetingDrillDownDataTable } from 'components/DrillDownDataTable/MeetingDrillDownTable'
import { TableWrapper } from 'components/TableWrapper'
import { useDateRangeContext } from 'contexts/dateRange'

import {
  formatMeetingBookedWithDrillDownQuery,
  meetingRepDrillDownFilters,
} from './MeetingBookedWith.helpers'
import { MeetingBookedWithRow } from './MeetingBookedWith.types'
import useMeetingBookedWith from './useMeetingBookedWith.hook'

const MeetingsRepMetrics = () => {
  const { tableData, isLoadingTableData } = useMeetingBookedWith()

  const { legendText, timePeriod } = useDateRangeContext()
  const [isDrillDownModalVisible, setIsDrillDownModalVisible] = useState(false)
  const [drillDownMetricTotal, setDrillDownMetricTotal] = useState(0)
  const [drillDownMeetingRepName, setDrillDownMeetingRepName] = useState('')
  const [drillDownCubeQuery, setDrillDownCubeQuery] = useState<CubeQuery>()

  const { trackViewEvent } = useTrackDrillDownModalView('MeetingRepTable')

  const handleDrillDownClick = (
    count: number,
    userId: number,
    displayName: string
  ) => {
    setDrillDownMetricTotal(count)
    setDrillDownMeetingRepName(displayName)
    const cubeQuery = formatMeetingBookedWithDrillDownQuery(
      userId.toString(),
      timePeriod.startDate,
      timePeriod.endDate
    )
    setDrillDownCubeQuery(cubeQuery)

    trackViewEvent({ totalRecords: drillDownMetricTotal ?? 0 })
    setIsDrillDownModalVisible(true)
  }

  const defaultColumns: TableColumn<MeetingBookedWithRow>[] = [
    {
      header: 'Drift teammate',
      value: 'displayName',
      cell: ({ displayName, status, avatarUrl }) => (
        <Flex alignItems="center">
          <UserAvatar size={'small'} name={displayName} imageUrl={avatarUrl} />
          <Text>
            {displayName}
            {status !== 'ENABLED' && ' (Deleted user)'}
          </Text>
        </Flex>
      ),
      disableSort: true,
    },
    {
      header: 'Meetings booked',
      value: 'count',
      cell: ({ count, userId, displayName }) => (
        <Button
          variant={'tertiary'}
          onClick={() => handleDrillDownClick(count, userId, displayName)}
        >
          {count}
        </Button>
      ),
      disableSort: true,
    },
  ]
  return (
    <TableWrapper
      title={'Who’s booking the most meetings?'}
      description={legendText.series}
    >
      <Table
        height={358}
        rows={tableData}
        columns={defaultColumns}
        isLoading={isLoadingTableData}
        loadingRows={5}
        disableBoxShadow={true}
      />
      {isDrillDownModalVisible && (
        <DrillDownDataModal
          open={isDrillDownModalVisible}
          title="Meetings booked"
          onClose={() => setIsDrillDownModalVisible(false)}
          cubeQuery={drillDownCubeQuery as CubeQuery}
          startDate={timePeriod?.startDate.toDate()}
          endDate={timePeriod?.endDate.toDate()}
          totalRecords={drillDownMetricTotal}
          filters={meetingRepDrillDownFilters(drillDownMeetingRepName)}
          onExportTriggered={trackDrillDownExportClick}
        >
          <MeetingDrillDownDataTable />
        </DrillDownDataModal>
      )}
    </TableWrapper>
  )
}

export default MeetingsRepMetrics
