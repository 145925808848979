import React, { useCallback, useState } from 'react'

import {
  MEETING_FACTS_DIMENSIONS,
  MeetingCount,
  MeetingsDrillDownBaseQuery,
  trackDrillDownExportClick,
  useQueryCompareDates,
  useTrackDrillDownModalView,
} from 'cube'
import { PointClickEventObject } from 'highcharts'
import moment, { Moment } from 'moment'

import { Card, Flex, Header, Text } from '@driftt/dds'
import {
  ChartSeriesData,
  CubeQuery,
  CubeQueryBuilder,
  DrillDownDataModal,
  getStartOfSeriesTimestamp,
  LineChart,
} from '@driftt/dds.reports'

import { Loading, MetricsError } from 'components'
import { MeetingDrillDownDataTable } from 'components/DrillDownDataTable/MeetingDrillDownTable'
import InfoIcon from 'components/InfoIcon'
import { useDateRangeContext } from 'contexts/dateRange'
import { formatSeriesData } from 'utils/Cube'

type DrillDownData = {
  query: CubeQuery
  totalRecords: number
  startDate: Moment
  endDate: Moment
}

const MeetingsBookedMetrics = () => {
  const { timePeriod, legendText } = useDateRangeContext()
  const [isDrillDownModalOpen, toggleDrillDownModal] = useState<boolean>(false)
  const { trackViewEvent } = useTrackDrillDownModalView(
    'MeetingsBookedByDayLineChart'
  )
  const [drillDownData, setDrillDownData] = useState<DrillDownData>({
    query: MeetingsDrillDownBaseQuery,
    totalRecords: 0,
    startDate: timePeriod.startDate,
    endDate: timePeriod.endDate,
  })

  const cubeQuery = new CubeQueryBuilder({
    query: MeetingCount,
  })
    .withName('MeetingQueryCountsByDay')
    .withinDateRange(
      MEETING_FACTS_DIMENSIONS.SCHEDULED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate(),
      'day'
    )
    .build()

  const cubeQueryPrevious = new CubeQueryBuilder({
    query: MeetingCount,
  })
    .withName('MeetingQueryCountsByDayPrevious')
    .withinDateRange(
      MEETING_FACTS_DIMENSIONS.SCHEDULED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate(),
      'day'
    )
    .build()

  const {
    data: meetingData,
    isLoading: meetingDataLoading,
    isError: meetingDataError,
  } = useQueryCompareDates({
    query: cubeQuery,
    previousQuery: cubeQueryPrevious,
  })

  const seriesDataCurrent =
    meetingData.current &&
    (formatSeriesData(meetingData.current) as ChartSeriesData)
  const seriesDataPrevious =
    meetingData.previous &&
    (formatSeriesData(meetingData.previous) as ChartSeriesData)

  const pointStart = getStartOfSeriesTimestamp(seriesDataCurrent)

  const handleChartPointClick = useCallback(
    (evt: PointClickEventObject) => {
      if (evt.point.y === 0) {
        return
      }

      const { point } = evt
      const startDate = moment(point.name).startOf('D')
      const endDate = moment(point.name).endOf('D')
      const totalRecords = point.y ?? 0

      const hydratedDrillDownQuery = new CubeQueryBuilder({
        query: MeetingsDrillDownBaseQuery,
      })
        .withName('MeetingsBookedLineChartDrillDownQuery')
        .withinDateRange(
          MEETING_FACTS_DIMENSIONS.SCHEDULED_AT,
          startDate.toDate(),
          endDate.toDate()
        )
        .build()

      setDrillDownData({
        query: hydratedDrillDownQuery,
        totalRecords,
        startDate,
        endDate,
      })
      toggleDrillDownModal(true)
      trackViewEvent({ totalRecords })
    },
    [trackViewEvent]
  )

  return (
    <Card css={{ height: '440px' }}>
      <Card.Header>
        <Flex alignItems="baseline">
          <Header variant="h3">Meetings booked with Drift</Header>
          <InfoIcon
            tooltipText={
              'All meetings booked through Drift including playbooks, manual calendar drops, and emails.'
            }
          />
        </Flex>
        <Text variant="metadata">{legendText.series}</Text>
      </Card.Header>
      <Card.Content css={{ height: '90%' }}>
        {meetingDataLoading && <Loading />}
        {meetingDataError && <MetricsError />}
        {seriesDataCurrent && seriesDataPrevious && (
          <>
            <LineChart
              onPointClick={handleChartPointClick}
              series={[
                {
                  label: legendText.series,
                  data: seriesDataCurrent,
                  pointStart: pointStart,
                },
                {
                  label: legendText.previousSeries,
                  data: seriesDataPrevious,
                  pointStart: pointStart,
                },
              ]}
              isLoading={false}
              hideTooltipHeader
            />
          </>
        )}
      </Card.Content>
      {isDrillDownModalOpen && (
        <DrillDownDataModal
          open={isDrillDownModalOpen}
          onClose={() => toggleDrillDownModal(false)}
          title="Meetings booked"
          totalRecords={drillDownData.totalRecords}
          startDate={drillDownData.startDate.toDate()}
          endDate={drillDownData.endDate.toDate()}
          cubeQuery={drillDownData.query}
          onExportTriggered={trackDrillDownExportClick}
        >
          <MeetingDrillDownDataTable />
        </DrillDownDataModal>
      )}
    </Card>
  )
}

export default MeetingsBookedMetrics
