import React from 'react'

import cx from 'classnames'

import logoSrc from './logo.svg'

const SalesforceLogo = ({ className }) => (
  <img className={cx(className)} src={logoSrc} alt="logo" />
)

export default SalesforceLogo
