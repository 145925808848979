import React, { useState } from 'react'

import { Button } from '@driftt/dds'
import { MarketableMomentModal } from '@driftt/marketable-moment-modal'
import { Div } from '@driftt/tide-core'

import BarGraph from 'images/bar-graph.svg'
import LineGraph from 'images/line-graph.svg'
import SweetSVG from 'images/sweet.svg'
import TeamPerformanceGraph from 'images/team-performance-graph.svg'

import './style.css'

const SLIDES = [
  {
    title: "You're ready to use Drift! See what's in store 👉",
    image: SweetSVG,
  },
  {
    title: 'See how playbooks are doing on your high-traffic pages',
    image: BarGraph,
  },
  {
    title: 'Monitor conversation volume over time',
    image: LineGraph,
  },
  {
    title: "Keep track of your team's performance",
    image: TeamPerformanceGraph,
    hasExternalNext: true,
  },
]

const ReportingUserOnboardedModal = () => {
  const [showModal, setShowModal] = useState(
    new URLSearchParams(window.location.search).get('reporting_user_onboarded')
  )

  const hideModal = () => setShowModal(false)

  const CloseModalButton = () => (
    <Button variant="secondary" onClick={hideModal}>
      Let's go!
    </Button>
  )

  return (
    <Div className="reporting-onboarded-modal">
      <MarketableMomentModal
        isOpen={showModal}
        onChange={hideModal}
        lastStepComponent={CloseModalButton}
        slides={SLIDES}
        type="carousel"
      />
    </Div>
  )
}

export default ReportingUserOnboardedModal
