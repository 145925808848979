import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_MEASURES,
} from 'cube/constants'

import { type CubeQuery } from '@driftt/dds.reports'

/**
 * CQL count base cube query
 */
export const CQLCount: CubeQuery = {
  name: 'CQLCount',
  queryName: 'CQLCount',
  query: {
    measures: [CONVERSATION_FACTS_MEASURES.CONVERSATION_COUNT],
    order: [[CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT, 'desc']],
    filters: [
      {
        member: CONVERSATION_FACTS_DIMENSIONS.CQL_SCORE,
        operator: 'equals',
        values: ['1', '2', '3'],
      },
    ],
  },
}
