import {
  receiveOpportunityDrilldownClosed,
  receiveOpportunityDrilldownCreated,
  receiveRevenueTotals,
  updateRevenueOptOut,
  updateSalesforceConnected,
} from 'actions/RevenueMetrics'
import { connect } from 'react-redux'

import { showTransientNotification } from '@driftt/traffic-control'

import RevenueMetrics from 'components/RevenueMetrics'
import { NEVER_SHOW_REVENUE_OPT_IN } from 'constants/userPropertyConstants'

const SALESFORCE_REVENUE_ORG_PROPERTY = 'salesforceRevenueMetricsEnabled'

const mapStateToProps = (state) => {
  return {
    orgId: state.user.orgId,
    userId: state.user.id,
    orgProperties: state.org.properties,
    hasSalesforceConnected: state.revenueMetrics.salesforceConnected,
    hideRevenueOptIn: state.user.settings[NEVER_SHOW_REVENUE_OPT_IN] || false,
    isRevenueOptedIn:
      state.org.properties[SALESFORCE_REVENUE_ORG_PROPERTY] || false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showTransientNotification: (config) => {
      dispatch(showTransientNotification(config))
    },
    onReceiveRevenueTotals: (totals) => {
      dispatch(receiveRevenueTotals(totals))
    },
    onUpdateSalesforceConnected: (isConnected) => {
      dispatch(updateSalesforceConnected(isConnected))
    },
    onUpdateRevenueOptOut: (isOptedOut) => {
      dispatch(updateRevenueOptOut(isOptedOut))
    },
    onReceiveOpportunityDrilldownCreated: (opportunitiesCreated) => {
      dispatch(receiveOpportunityDrilldownCreated(opportunitiesCreated))
    },
    onReceiveOpportunityDrilldownClosed: (opportunitiesClosed) => {
      dispatch(receiveOpportunityDrilldownClosed(opportunitiesClosed))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenueMetrics)
