import React from 'react'

export const HeatMapEmptyState = () => {
  return (
    <svg
      width="168"
      height="92"
      viewBox="0 0 168 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="57.5" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="57.5" y="16" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="57.5" y="32" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="57.5" y="48" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="57.5" y="64" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="57.5" y="80" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="0.5" y="16" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="0.5" y="32" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="0.5" y="48" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="0.5" y="64" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="0.5" y="80" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="114.5" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="114.5" y="16" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="114.5" y="32" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="114.5" y="48" width="53" height="12" rx="2" fill="#DCECFE" />
      <rect x="114.5" y="64" width="53" height="12" rx="2" fill="#BBDBFE" />
      <rect x="114.5" y="80" width="53" height="12" rx="2" fill="#DCECFE" />
    </svg>
  )
}
