import { get } from 'lodash'
import { path } from 'ramda'
import { createSelector } from 'reselect'

import config from 'config'

export const userSelector = (state) => state.user
export const userSettingsSelector = (state) =>
  get(userSelector(state), 'settings', {})

export const userSquareCropAvatarSelector = (state) => {
  const user = userSelector(state)
  return get(user, 'avatars.squareCropImage200', get(user, 'avatarUrl'))
}

export const profileHandle = (state) =>
  path(['profile', 'globalHandle'], userSelector(state))

export const profileHandleUrlSelector = createSelector(userSelector, (user) => {
  return `${config.PROFILE_DOMAIN}/${path(['profile', 'globalHandle'], user)}`
})

export const getUserDisplayName = (user) =>
  user ? user.name || user.email : null
