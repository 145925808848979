import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_MEASURES,
} from 'cube/constants'

import { type CubeQuery } from '@driftt/dds.reports'

/**
 * Emails captured base query
 */
export const EmailsCapturedCount: CubeQuery = {
  name: 'EmailsCapturedCount',
  queryName: 'EmailsCapturedCount',
  query: {
    measures: [CONVERSATION_FACTS_MEASURES.CONVERSATION_COUNT],
    order: [[CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT, 'desc']],
    filters: [
      {
        member: 'FACTDriftChatConversationV2.hasEmailCaptured',
        operator: 'equals',
        values: ['true'],
      },
    ],
  },
}
