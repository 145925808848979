import { tracking } from '@driftt/traffic-control'

export const trackDrillDownExportClick = (
  metricType: string,
  startDate: Date,
  endDate: Date,
  totalCount: number,
  pageName: string = window.location.pathname
) =>
  tracking.trackEvent('CUBE_DRILL_DOWN_EXPORT_CLICK', {
    metricType,
    startDate,
    endDate,
    totalCount,
    pageName,
  })
