import Qs from 'qs'

import { createAuthedHttpClient } from '@driftt/web-auth'

import config from 'config'

export const CustomerAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.CUSTOMER_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const GrowthAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.GROWTH_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const FalcorAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.FALCOR_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const ConversationAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.CONVERSATION_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const DriftMetricsAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.METRICS_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const ReportAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.REPORT_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const BillingAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.BILLING_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

export const MeetingsAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.MEETINGS_API,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})
