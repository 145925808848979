import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_MEASURES,
} from 'cube/constants'

import type { CubeQuery } from '@driftt/dds.reports'

/**
 * Base cube query for conversation count by created from URL.
 */
export const ConversationsCreatedFromUrlBaseQuery: CubeQuery = {
  name: 'ConversationsCreatedFromUrl',
  queryName: 'ConversationsCreatedFromUrl',
  query: {
    measures: [CONVERSATION_FACTS_MEASURES.CONVERSATION_COUNT],
    dimensions: [CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_FROM_URL],
    order: [[CONVERSATION_FACTS_MEASURES.CONVERSATION_COUNT, 'desc']],
  },
}
