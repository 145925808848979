import {
  CHAT_APP_USER_DIMENSIONS,
  DRIFT_CHAT_END_USERS_DIMENSIONS,
  MEETING_FACTS_DIMENSIONS,
} from 'cube/constants'

import { type CubeQuery } from '@driftt/dds.reports'

/**
 * Base cube query for the meeting drill down view.
 */
export const MeetingsDrillDownBaseQuery: CubeQuery = {
  name: 'MeetingsDrillDownQuery',
  queryName: 'MeetingsDrillDownQuery',
  query: {
    dimensions: [
      DRIFT_CHAT_END_USERS_DIMENSIONS.DISPLAY_NAME,
      CHAT_APP_USER_DIMENSIONS.DISPLAY_NAME,
      MEETING_FACTS_DIMENSIONS.BOOKED_FOR,
      MEETING_FACTS_DIMENSIONS.MEETING_SOURCE,
      MEETING_FACTS_DIMENSIONS.STATUS,
      MEETING_FACTS_DIMENSIONS.CONVERSATION_ID,
    ],
    order: [[MEETING_FACTS_DIMENSIONS.BOOKED_FOR, 'desc']],
  },
}
