import { assign } from 'lodash'

import { GlobalStore, tracking } from '@driftt/traffic-control'

import { SEGMENT_TRACKING_BLACKLIST } from 'constants/trackingConstants'

/**
 * @deprecated Use `tracking.trackEvent` from `tracking-control`
 */
export const track = (eventName, properties) => {
  const user = GlobalStore.getState().user
  const orgId = user.orgId

  if (!window.analytics || SEGMENT_TRACKING_BLACKLIST.includes(orgId)) {
    return
  }
  const propertiesWithEmail = assign(
    {},
    { email: user.email, orgId },
    properties
  )
  window.analytics.track(eventName, propertiesWithEmail)
}

// Export this so that devs don't have to import trackers from two different places
export const trackPageView = tracking.trackPageView
export const trackEvent = tracking.trackEvent

export const trackCSVDownloadClickedEvent = (
  page,
  csvType,
  startDate,
  endDate
) =>
  trackEvent('CSV Download Clicked', {
    page,
    csv_type: csvType,
    start_date: startDate,
    end_date: endDate,
  })

export const trackClickedEvent = (pageName, buttonName = 'Button') =>
  trackEvent(`${buttonName} Clicked`, {
    page: pageName,
  })
