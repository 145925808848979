import { connect } from 'react-redux'

import MeetingMetrics from 'components/MeetingMetrics'

const mapStateToProps = (state) => {
  return {
    timezone: state.embed.timezone || 'America/New_York',
  }
}

export default connect(mapStateToProps)(MeetingMetrics)
