import {
  OverviewConversationDrillDownQuery,
  OverviewCQLDrillDownQuery,
  OverviewEmailsCapturedDrillDownQuery,
} from 'cube/queries'

export const OVERVIEW_METRICS_HEADER_TOOLTIP =
  'This is the high-level view of your Drift account over the time frame selected'

export const DRIFT_OVERVIEW_METRIC_TYPES = {
  CONVERSATIONS: 'CONVERSATIONS',
  EMAILS_CAPTURED: 'EMAILS_CAPTURED',
  CQLS: 'CQLS',
} as const

export const DRIFT_OVERVIEW_METRIC_TYPES_LABELS = {
  CONVERSATIONS: 'Chats',
  EMAILS_CAPTURED: 'Emails captured',
  CQLS: 'CQLs',
} as const

export enum TIME_PERIOD_TYPES {
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
}

export const MetricTypeToDrillDownQuery = {
  [DRIFT_OVERVIEW_METRIC_TYPES.CONVERSATIONS]:
    OverviewConversationDrillDownQuery,
  [DRIFT_OVERVIEW_METRIC_TYPES.EMAILS_CAPTURED]:
    OverviewEmailsCapturedDrillDownQuery,
  [DRIFT_OVERVIEW_METRIC_TYPES.CQLS]: OverviewCQLDrillDownQuery,
} as const
