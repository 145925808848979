import React from 'react'

import { Flex, FlexProps } from '@driftt/dds'
import { CircleLoader } from '@driftt/tide-core'

export const Loading = (props: Omit<FlexProps, 'children'>) => (
  <Flex
    fullWidth
    fullHeight
    justifyContent={'center'}
    alignItems={'center'}
    {...props}
  >
    <CircleLoader size={'large'} />
  </Flex>
)
