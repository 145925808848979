import { ResultSet } from '@cubejs-client/core'
import { equals, isEmpty, pathOr } from 'ramda'

export const getMeasureTotal = (resultSet: ResultSet) => {
  const query = resultSet?.query()
  const measure = pathOr(null, ['measures', 0], query)
  const rawData = resultSet?.rawData()

  if (isEmpty(rawData)) {
    return 0
  }

  if (resultSet && measure) {
    const results = resultSet.tablePivot()
    return Number(results[0][measure])
  }

  return 0
}

export const getDeltaAsPercent = (
  currentTotal: number,
  previousTotal: number
) => {
  const currentTotalValue = currentTotal || 0
  const previousTotalValue = previousTotal || 0

  const difference = currentTotalValue - previousTotalValue
  return !equals(previousTotalValue, 0) ? (difference / previousTotal) * 100 : 0
}
