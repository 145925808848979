import React from 'react'

import { Box, Icon, Tooltip } from '@driftt/dds'
import { faCircleInfo } from '@driftt/dds.icons/dist/light/faCircleInfo'

import './style.css'

const InfoIcon = ({ tooltipText }) => (
  <Box as="span" className="info-icon-container">
    <Tooltip side="top" content={tooltipText}>
      <Icon icon={faCircleInfo} className="info-icon-image" size="medium" />
    </Tooltip>
  </Box>
)

export default InfoIcon
