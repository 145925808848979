import { CustomerAPI } from 'api'

export const patchUserProperties = async (userId, properties) => {
  return CustomerAPI.patch(`/users/${userId}/properties`, properties)
}

export const fetchEndUserById = async (endUserId) => {
  return CustomerAPI.get(`/end_users/${endUserId}`).then(
    (response) => response.data
  )
}

export const bulkFetchEndUsers = async ({ endUserIds }) => {
  return CustomerAPI.post(`/end_users/find`, { endUserIds }).then(
    (response) => response.data
  )
}

export const fetchOrgUsers = async (orgId, { userId }) => {
  return CustomerAPI.get(`/organizations/${orgId}/users`, {
    params: {
      userId,
    },
  }).then((response) => response.data)
}
