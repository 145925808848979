import {
  CHAT_APP_USER_DIMENSIONS,
  MEETING_FACTS_DIMENSIONS,
  MeetingsDrillDownBaseQuery,
} from 'cube'
import { Moment } from 'moment'

import { type CubeQuery, CubeQueryBuilder } from '@driftt/dds.reports'
import { type FilterChipProps } from '@driftt/dds.reports/dist/packages/reports/src/lib/components/FilterChip'
export const formatMeetingBookedWithDrillDownQuery = (
  userId: string,
  startDate: Moment,
  endDate: Moment
): CubeQuery => {
  return new CubeQueryBuilder({ query: MeetingsDrillDownBaseQuery })
    .withName(`MeetingBookedWithDrillDown-${userId}`)
    .withQueryName(`MeetingBookedWithDrillDown-${userId}`)
    .withFilters([
      {
        member: CHAT_APP_USER_DIMENSIONS.USER_ID,
        operator: 'equals',
        values: [userId],
      },
    ])
    .withinDateRange(
      MEETING_FACTS_DIMENSIONS.SCHEDULED_AT,
      startDate.toDate(),
      endDate.toDate()
    )
    .build()
}

export const meetingRepDrillDownFilters = (name: string): FilterChipProps[] => {
  return [
    {
      filterKey: 'Drift teammate',
      filterOperator: 'is',
      filterValue: name,
    },
  ]
}
