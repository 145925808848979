/* eslint-disable sonarjs/no-duplicate-string */
import React, { Fragment } from 'react'

import { Button } from '@driftt/dds'
import { AsyncButton, Ribbon, Span } from '@driftt/tide-core'

import { toggleBotBuilderBranchingGate } from 'api/Gates'

const toggleBotBuilderBranching = () => {
  const enable = true
  return toggleBotBuilderBranchingGate(enable).then(() => {
    window.open('https://app.drift.com/playbooks', '_blank')
  })
}

export const MarketableMoment = Object.freeze({
  March: {
    Banner: {
      title: (
        <Span className="bot-builder-mm-title">
          <Ribbon text="New" />
          Introducing a new way to build bots!
        </Span>
      ),
      description:
        "It's now easier than ever to build your bot conversations. Add questions exactly where you want them, and visualize conversation paths using the new horizontal flow builder.",
      buttonContainer: (
        <AsyncButton promise={toggleBotBuilderBranching} type="secondary">
          Try it now
        </AsyncButton>
      ),
    },
  },
  April: {
    Banner: {
      icon: require('images/MarketableMomentIcons/april.svg'),
      title: 'Introducing Drift Meetings 2.0',
      description:
        'Spend more of your time selling, and less of it scheduling. Book meetings faster, save time, and make the buying process more personal with less work.',
      buttonContainer: (
        <Fragment>
          <Button
            onClick={() =>
              window.open(
                'https://help.salesloft.com/s/article/Drift-Bot-Skill-Book-a-Meeting',
                '_blank'
              )
            }
            variant="secondary"
          >
            Learn more
          </Button>
          <Button onClick={() => window.open('/settings/calendar#mm-modal')}>
            Try Meetings 2.0
          </Button>
        </Fragment>
      ),
    },
    Modal: [
      {
        buttonCopy: 'Check it out!',
        ctaPath: '/settings/calendar#mm-modal',
        gradientStart: '#C86DD7',
        gradientEnd: '#3023AE',
        svg: require('images/MarketableMomentModals/april.svg'),
        copy: 'Spend more of your time selling, and less of it scheduling.  Book meetings faster, save time, and make the buying process more personal with less work.',
      },
    ],
  },
  May: {
    Banner: {
      icon: require('images/MarketableMomentIcons/may.svg'),
      title: 'Introducing LeadBot 2.0',
      description:
        "It's now easier than ever to build a bot that qualifies visitors on your website. With the new visual bot builder, you can design a conversation in minutes - that asks questions, crafts responses and sets goals",
      buttonContainer: (
        <Fragment>
          <Button
            onClick={() =>
              window.open(
                'https://help.salesloft.com/s/article/How-To-Build-Bot-Playbooks',
                '_blank'
              )
            }
            variant="secondary"
          >
            Learn more
          </Button>
          <Button onClick={() => window.open('/marketplace?tab=chat')}>
            Check out LeadBot 2.0
          </Button>
        </Fragment>
      ),
    },
    Modal: {
      buttonCopy: 'Check out LeadBot 2.0',
      ctaPath: '/marketplace?tab=chat',
      gradientStart: '#C86DD7',
      gradientEnd: '#3023AE',
      svg: require('images/MarketableMomentModals/may.png'),
      title: 'Introducing',
      description: 'LeadBot 2.0',
      copy: 'Drift’s brand new bot builder makes it easier than ever to build conversations',
    },
  },
  June: {
    Banner: {
      icon: require('images/MarketableMomentIcons/june.svg'),
      title: 'Introducing Drift Help',
      description:
        'Start automating your support by combining the power of Driftbot and Drift Help. After all, helping is the new selling.',
      buttonContainer: (
        <Fragment>
          <Button
            variant="secondary"
            onClick={() => window.open('http://drift.com/help')}
          >
            Learn More
          </Button>
          <Button onClick={() => window.open('/help')}>
            Check out Drift Help
          </Button>
        </Fragment>
      ),
    },
    Modal: [
      {
        buttonCopy: 'Check it out!',
        ctaPath: '/help',
        gradientStart: '#AA55D5',
        gradientEnd: '#FF5A39',
        svg: require('images/MarketableMomentModals/june.svg'),
        title: 'Introducing',
        description: 'Drift Help',
        copy: 'Automate your support by combining the power of Driftbot and Drift Help. After all, helping is the new selling.',
      },
    ],
  },
  July: {
    Banner: {
      icon: require('images/MarketableMomentIcons/july.svg'),
      title: 'Introducing Drift Signatures',
      description:
        'Keep your whole team on-brand and promote your next campaign by setting up a signature playbook with just a few clicks.',
      buttonContainer: (
        <Fragment>
          <Button
            variant="secondary"
            onClick={() => window.open('http://drift.com/signatures')}
          >
            Learn More
          </Button>
          <Button onClick={() => window.open('/marketplace?tab=signature')}>
            Check out Drift Signatures
          </Button>
        </Fragment>
      ),
    },
    Modal: [
      {
        title: 'Introducing',
        description: 'Drift Signatures',
        copy: 'Close the loop by driving the people you’re already talking to back to your site with branded signatures, right through the Drift Chrome extension.',
        gradientStart: '#157AFB',
        gradientEnd: '#69D1B0',
        svg: require('images/MarketableMomentModals/july-1@2x.png'),
      },
      {
        title: 'Introducing',
        description: 'Drift Signatures',
        copy: 'Build a dynamic signature with multiple banner images to let your reps choose the best campaign for their email recipient.',
        gradientStart: '#157AFB',
        gradientEnd: '#69D1B0',
        svg: require('images/MarketableMomentModals/july-2@2x.png'),
        buttonCopy: 'Try it Out',
        ctaPath: '/marketplace?tab=email#selectsignature',
      },
    ],
  },
  August: {
    Banner: {},
    Modal: [],
  },
  September: {
    Banner: {},
    Modal: [],
  },
  October: {
    Banner: {
      icon: require('images/MarketableMomentIcons/september.svg'),
      title:
        'The easiest way to start conversations with your ideal customers NOW.',
      description:
        'Skip the form. Skip the funnel. Go straight to the conversation. Now any bot playbook can be accessed from a landing page.',
      buttonContainer: (
        <Fragment>
          <Button
            variant="secondary"
            onClick={() =>
              window.open(
                'https://help.salesloft.com/s/article/Conversational-Landing-Pages',
                '_blank'
              )
            }
          >
            Learn More
          </Button>
          <Button onClick={() => window.open('/marketplace?tab=chat')}>
            Create a new playbook
          </Button>
        </Fragment>
      ),
    },
    Modal: [
      {
        title: 'Introducing',
        description: 'Conversational Landing Pages',
        subheadline:
          'The easiest way to start conversations with your ideal customers NOW',
        copy: 'Skip the form. Skip the funnel. Go straight to the conversation. Now any bot playbook can be accessed from a landing page.',
        gradientStart: '#C755D5',
        gradientEnd: '#F55C5C',
        svg: require('images/MarketableMomentModals/september-1@2x.png'),
      },
      {
        title: 'Introducing',
        description: 'Conversational Landing Pages',
        subheadline: 'Customize and share',
        copy: 'You can customize a conversational landing page for any bot conversation, and share the custom link as you would for any other landing page.',
        gradientStart: '#C755D5',
        gradientEnd: '#F55C5C',
        svg: require('images/MarketableMomentModals/september-2@2x.png'),
        buttonCopy: 'Create A Playbook',
        ctaPath: '/marketplace?tab=chat',
      },
    ],
  },
  November: {
    Banner: {
      icon: require('images/MarketableMomentIcons/may.svg'),
      title: 'Introducing Driftbot for Help',
      description:
        'Answer 100% of support questions so your sales team can focus on what they do best.',
      buttonContainer: (
        <Fragment>
          <Button
            variant="secondary"
            onClick={() => window.open('help.salesloft.com', '_blank')}
          >
            Learn More
          </Button>
          <Button onClick={() => window.open('/settings/routing')}>
            Enable
          </Button>
        </Fragment>
      ),
    },
    Modal: [
      {
        title: 'Introducing',
        description: 'Driftbot for Help',
        subheadline:
          'The easiest way to start conversations with your ideal customers NOW',
        copy: 'Answer 100% of support questions so your sales team can focus on what they do best.',
        gradientStart: '#C86DD7',
        gradientEnd: '#3023AE',
        buttonCopy: 'Enable',
        ctaPath: '/settings/routing',
        svg: require('images/MarketableMomentModals/november.png'),
      },
    ],
  },
  January: {
    Banner: {},
    Modal: [],
  },
})

export const MARKETABLE_MOMENT_DATE = 'March 5, 2019 09:00:00'
