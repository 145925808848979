import React from 'react'

import { Button, Flex, Header, Link, styled, Text } from '@driftt/dds'

import SalesforceLogo from '../SalesforceLogo'

import './style.css'

const LEARN_MORE_LINK =
  'https://help.salesloft.com/s/article/Integrating-Drift-with-Salesforce'
const SALESFORCE_INTEGRATION_LINK = '/settings2/integrations/detail/salesforce'

const ConnectSalesforceFlex = styled(Flex, {
  backgroundColor: '$gray2',
  minHeight: '370px',
})

const RevenueConnectSection = ({ onOptOutClick }) => (
  <ConnectSalesforceFlex
    fullHeight
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <SalesforceLogo className="salesforce-metrics-logo" />
    <Header variant="h2">Revenue influenced by Drift</Header>
    <Text variant="body">
      Connect Salesforce to see how Drift influences your opportunites,
      pipeline, and closed won.{' '}
      <Link variant="inline" href={LEARN_MORE_LINK} external={true}>
        Learn more
      </Link>
    </Text>
    <Button
      onClick={() =>
        window.open(
          SALESFORCE_INTEGRATION_LINK,
          '_blank',
          'noopener,noreferrer'
        )
      }
    >
      Connect Salesforce
    </Button>
    <Button onClick={onOptOutClick} variant="tertiary">
      Don't ask again
    </Button>
  </ConnectSalesforceFlex>
)

export default RevenueConnectSection
