import { findKey, isArray, merge } from 'lodash'

import { GoogleScopes } from 'constants/Integrations'

const initialState = {
  google: {},
}

const scopesReducer = (state = initialState, action) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'RECIEVE_GOOGLE_SCOPES':
      if (!action.scopes.length || !isArray(action.scopes)) {
        return merge({}, { google: {} })
      }

      // eslint-disable-next-line no-case-declarations
      const googleScopes = action.scopes.map((scope) => {
        return findKey(GoogleScopes, (googleScope) => googleScope === scope)
      })

      return merge({}, state, { google: googleScopes })

    default:
      return state
  }
}

export default scopesReducer
