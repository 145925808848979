import React from 'react'
import ReactDOM from 'react-dom'

import App from 'containers/App'

import LoadStyles from '@driftt/tide-core/dist/styles'
import ConnectedApp from '@driftt/traffic-control'

import config from 'config'

import { rootReducer } from './reducers/rootReducer'

import '@driftt/react-dates/initialize'

import '@driftt/react-dates/lib/css/_datepicker.css'

LoadStyles()

ReactDOM.render(
  <ConnectedApp
    reducers={rootReducer}
    render={() => <App />}
    authClientId={config.AUTH_CLIENT_ID}
    sentryId={config.SENTRY_KEY}
  />,
  document.getElementById('root')
)
