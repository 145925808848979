import React, { useEffect } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import DiscoverHome from 'containers/DiscoverHome'
import Home from 'containers/Home'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import { ThemeProvider, ToastProvider } from '@driftt/dds'
import { DriftAnalytics } from '@driftt/dds.analytics'
import { CubeDataProvider } from '@driftt/dds.reports'

import config from 'config'
import DateRangeContext from 'contexts/dateRange'
import useDateRange from 'hooks/useDateRange'

const queryClient = new QueryClient()

const App = ({
  isJITProvisioned,
  userEmail,
  userHasFullDashboardAccess,
  isMarketerUser,
  isServiceUser,
  isSalesRepUser,
  isSalesManagerUser,
  isPlaybooksUser,
  isRbacUser,
  driftUser,
}) => {
  const { timePeriod, onChangeTimePeriod, legendText } = useDateRange()

  useEffect(() => {
    DriftAnalytics.initialize({ user: driftUser })
  }, [driftUser])

  const Index = () => {
    const redirectToOnboarding = (userEmail) => {
      const LETS_GO_URL = (config, email) =>
        `${config.ONBOARDING_URL}?email=${email}&sso=true`
      window.location.href = LETS_GO_URL(config, userEmail)
    }

    if (!userHasFullDashboardAccess) {
      if (isJITProvisioned) {
        redirectToOnboarding(userEmail)
      }

      if (isRbacUser) {
        if (isMarketerUser) {
          window.location.href = `${config.APP_URL}/playbooks`
        } else if (isSalesManagerUser) {
          window.location.href = `${config.APP_URL}/reports/real-time`
        } else if (isSalesRepUser) {
          window.location.href = `${config.APP_URL}/activity`
        } else if (isServiceUser) {
          window.location.href = `${config.APP_URL}/conversations`
        } else {
          return <Redirect to="/discover" />
        }
      } else {
        window.location.href = isPlaybooksUser
          ? `${config.APP_URL}/playbooks`
          : `${config.APP_URL}/inboxes`
      }

      return null
    }
    return <Redirect to="/dashboard" />
  }

  return (
    <Router basename="/home">
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <CubeDataProvider>
            <DateRangeContext.Provider
              value={{ timePeriod, onChangeTimePeriod, legendText }}
            >
              <ToastProvider>
                <Switch>
                  <Route path="/discover" component={DiscoverHome} />
                  {userHasFullDashboardAccess && (
                    <Route path="/dashboard" component={Home} />
                  )}
                  <Route path="/" component={Index} />
                </Switch>
              </ToastProvider>
            </DateRangeContext.Provider>
          </CubeDataProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
