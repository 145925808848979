import { connect } from 'react-redux'

import { MARKETABLE_MOMENT_DATE } from 'constants/MarketableMoment'
import {
  VIEWED_JULY_MM_MODAL_PROPERTY,
  VIEWED_JUNE_MM_MODAL_PROPERTY,
  VIEWED_NOVEMBER_MM_MODAL_PROPERTY,
  VIEWED_OCTOBER_MM_MODAL_PROPERTY,
} from 'constants/userPropertyConstants'
import MarketingDashboard from 'pages/Home'

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    isAfterMMDate: Date.now() > Date.parse(MARKETABLE_MOMENT_DATE),
    viewedJulyMMModalProperty:
      state.user.settings[VIEWED_JULY_MM_MODAL_PROPERTY],
    viewedJuneMMModalProperty:
      state.user.settings[VIEWED_JUNE_MM_MODAL_PROPERTY],
    viewedOctoberMMModalProperty:
      state.user.settings[VIEWED_OCTOBER_MM_MODAL_PROPERTY],
    viewedNovemberMMModalProperty:
      state.user.settings[VIEWED_NOVEMBER_MM_MODAL_PROPERTY],
  }
}

export default connect(mapStateToProps, undefined)(MarketingDashboard)
