import {
  CHAT_APP_USER_DIMENSIONS,
  MEETING_FACTS_MEASURES,
} from 'cube/constants'

import type { CubeQuery } from '@driftt/dds.reports'

/**
 * Base cube query for meetings by user id.
 */
export const MeetingsBookedWithBaseQuery: CubeQuery = {
  name: 'MeetingsBookedWith',
  queryName: 'MeetingsBookedWith',
  query: {
    measures: [MEETING_FACTS_MEASURES.MEETING_COUNT],
    dimensions: [
      CHAT_APP_USER_DIMENSIONS.DISPLAY_NAME,
      CHAT_APP_USER_DIMENSIONS.STATUS,
      CHAT_APP_USER_DIMENSIONS.AVATAR_URL,
      CHAT_APP_USER_DIMENSIONS.USER_ID,
    ],
    order: [[MEETING_FACTS_MEASURES.MEETING_COUNT, 'desc']],
  },
}
