import { merge } from 'lodash'

const initialState = {
  queries: {},
  results: {},
}

const meetingReportsReducer = (state = initialState, action) => {
  const queryConfig = action.data ? action.data.queryConfig : {}
  const response = action.data ? action.data.response : {}
  let queries = {}
  let results = {}

  switch (action.type) {
    case 'MEETING_DATA_SUCCESS':
      queries = {
        [JSON.stringify(queryConfig)]: {
          isFinished: true,
          isPending: false,
          hasFailed: false,
        },
      }
      results = {
        [JSON.stringify(queryConfig)]: response,
      }
      return merge({}, state, { queries, results })

    case 'MEETING_DATA_PENDING':
      queries = {
        [JSON.stringify(queryConfig)]: {
          isFinished: false,
          isPending: true,
          hasFailed: false,
        },
      }
      results = {
        [JSON.stringify(queryConfig)]: {},
      }
      return merge({}, state, { queries, results })

    case 'MEETING_DATA_FAILED':
      queries = {
        [JSON.stringify(queryConfig)]: {
          isFinished: true,
          isPending: false,
          hasFailed: true,
        },
      }
      results = {
        [JSON.stringify(queryConfig)]: {},
      }
      return merge({}, state, { queries })

    default:
      return state
  }
}

export default meetingReportsReducer
