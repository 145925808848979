import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  DEFAULT_TIME_PERIOD,
  isNilOrEmpty,
  mapDatesToSeriesLabels,
} from 'utils/dateRangeUtils'

/**
 * Hook that handles date range management
 * @returns { timePeriod, legendText, onChangeTimePeriod }
 */
const useDateRange = () => {
  const [timePeriod, setTimePeriod] = useState(DEFAULT_TIME_PERIOD)
  const [legendText, setLegendText] = useState(
    mapDatesToSeriesLabels(DEFAULT_TIME_PERIOD)
  )

  const onChangeTimePeriod = useCallback((timePeriod) => {
    setTimePeriod(timePeriod)
  }, [])

  useEffect(() => {
    if (!isNilOrEmpty(timePeriod)) {
      setLegendText(mapDatesToSeriesLabels(timePeriod))
    }
  }, [timePeriod])

  return useMemo(
    () => ({
      timePeriod,
      legendText,
      onChangeTimePeriod,
    }),
    [timePeriod, legendText, onChangeTimePeriod]
  )
}

export default useDateRange
