import React from 'react'

import { Card, Header, Text } from '@driftt/dds'
import { CubeQueryStringAdapter } from '@driftt/dds.reports'

import config from 'config/index'
import { CONVO_DETAIL_REPORT_PATH } from 'constants/reportConstants'
import { useDateRangeContext } from 'contexts/dateRange'

import {
  formatHeatMapDrillDownQuery,
  getDayAsString,
} from './ConversationHeatMapChart.helpers'
import { HeatMap } from './HeatMap'
import useConversationHeatMapData from './useConversationHeatMapData.hook'

/**
 * Component that displays a heat map of conversations by time of day for the given time period.
 */
export const ConversationHeatMapChart = () => {
  const { chartData, isLoading, isEmpty } = useConversationHeatMapData()

  const { timePeriod } = useDateRangeContext()

  const handlePointClick = ({ point }: { point: any }) => {
    const { x: dayOfWeek, y: hourOfDay } = point
    const dayOfWeekAsString: string = getDayAsString(dayOfWeek)

    const drillDownQuery = formatHeatMapDrillDownQuery(
      hourOfDay,
      dayOfWeekAsString,
      timePeriod.startDate,
      timePeriod.endDate
    )

    // Drill down to conversation detail report
    const drillDownQueryString =
      CubeQueryStringAdapter.toQueryString(drillDownQuery)
    const drillDownURL = `${config.APP_URL}${CONVO_DETAIL_REPORT_PATH}?${drillDownQueryString}`
    window.open(drillDownURL, '_blank')
  }

  return (
    <Card css={{ height: '475px' }}>
      <Card.Header>
        <Header variant={'h3'}>Chats by time of day</Header>
        <Text variant={'metadata'}>
          How many chats happen during specific days and hours?
        </Text>
      </Card.Header>
      <Card.Content css={{ height: '100%' }}>
        <HeatMap
          series={chartData}
          onPointClick={handlePointClick}
          isLoading={isLoading}
          isEmpty={isEmpty}
        />
      </Card.Content>
    </Card>
  )
}
