export const CubeSchemaTables = {
  APP_USERS: 'DriftChatAppUsers',
  CONVERSATIONS: 'FACTDriftChatConversationV2',
  END_USERS: 'DriftChatEndUsers',
  MEETINGS: 'DriftChatSchedulingEvents',
} as const

/**
 * Zone dimensions are dates returned from the database converted into the user's timezone
 *
 * @docs https://github.com/Driftt/cube-reporting-schemas/blob/master/README.md#inconsistent-timezone-handling
 */
export const CONVERSATION_FACTS_ZONE_DIMENSIONS = {
  CONVERSATION_CREATED_AT:
    'FACTDriftChatConversationV2.conversationCreatedAtWithZone',
} as const

export const CONVERSATION_FACTS_DIMENSIONS = {
  FIRST_USER_INTERACTED_AT:
    'FACTDriftChatConversationV2.firstEnduserInteractionAt',
  HOUR_OF_DAY: 'FACTDriftChatConversationV2.hourOfDay',
  DAY_OF_WEEK: 'FACTDriftChatConversationV2.dayOfWeek',
  CONVERSATION_CREATED_FROM_URL:
    'FACTDriftChatConversationV2.conversationCreatedFromUrl',
  DURATION_BETWEEN_FIRST_INTERACTION_AND_FIRST_CLOSE_MS:
    'FACTDriftChatConversationV2.durationBetweenFirstInteractionAndFirstCloseMillis',
  CONVERSATION_ID: 'FACTDriftChatConversationV2.conversationId',
  PLAYBOOK_ID: 'FACTDriftChatConversationV2.playbookId',
  INBOX_ID: 'FACTDriftChatConversationV2.inboxId',
  PARTICIPANT_STATUS: 'FACTDriftChatConversationV2.participantStatus',
  CONVERSATION_CLOSE_AT: 'FACTDriftChatConversationV2.firstConversationCloseAt',
  END_USER_ID: 'FACTDriftChatConversationV2.enduserId',
  AGENT_FIRST_REPSONSE_TIME:
    'FACTDriftChatConversationV2.firstDriftuserInteractionAt',
  CQL_SCORE: 'FACTDriftChatConversationV2.cqlScore',
  LAST_CLASSIFICATION_ID: 'FACTDriftChatConversationV2.lastClassificationId',
  APP_CONVERSATION_URL: 'FACTDriftChatConversationV2.conversationDriftAppUrl',
  HAS_EMAIL_CAPTURED: 'FACTDriftChatConversationV2.hasEmailCaptured',
  CONVERSATION_CREATED_AT: 'FACTDriftChatConversationV2.conversationCreatedAt',
  CONVERSATION_STATUS: 'FACTDriftChatConversationV2.conversationStatus',
} as const

export const CONVERSATION_FACTS_MEASURES = {
  CONVERSATION_COUNT: 'FACTDriftChatConversationV2.count',
} as const

export const MEETING_FACTS_DIMENSIONS = {
  SCHEDULED_AT: 'DriftChatSchedulingEvents.schedulingEventScheduledAt',
  DRIFT_USER_ID: 'DriftChatSchedulingEvents.driftUserId',
  MEETING_SOURCE: 'DriftChatSchedulingEvents.meetingSource',
  STATUS: 'DriftChatSchedulingEvents.status',
  CONVERSATION_ID: 'DriftChatSchedulingEvents.conversationId',
  BOOKED_FOR: 'DriftChatSchedulingEvents.schedulingEventSlotStartAt',
} as const

export const MEETING_FACTS_MEASURES = {
  MEETING_COUNT: 'DriftChatSchedulingEvents.count',
}

export const CHAT_APP_USER_DIMENSIONS = {
  DISPLAY_NAME: 'DriftChatAppUsers.displayName',
  STATUS: 'DriftChatAppUsers.status',
  AVATAR_URL: 'DriftChatAppUsers.avatarUrl',
  USER_ID: 'DriftChatAppUsers.userId',
}

export const DRIFT_CHAT_END_USERS_DIMENSIONS = {
  NAME: 'DriftChatEndUsers.name',
  EMAIL: 'DriftChatEndUsers.email',
  DISPLAY_NAME: 'DriftChatEndUsers.displayName',
}
