import { useCallback, useMemo } from 'react'

import { type ResultSet } from '@cubejs-client/core'
import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_MEASURES,
} from 'cube/constants'
import * as R from 'ramda'

import { CubeQueryBuilder, useCubeQuery } from '@driftt/dds.reports'

import { useDateRangeContext } from 'contexts/dateRange'
import { isNilOrEmpty } from 'utils/ramda'

import { DayToNumberMap } from './ConversationHeatMapChart.helpers'
import { ConversationSeriesData } from './ConversationHeatMapChart.types'

export interface ConversationHeatMapResult {
  chartData: number[][]
  isLoading: boolean
  isEmpty: boolean
  hasError: boolean
}

/***
 * Hook that uses cube to fetch data for the conversations by time of day heat map.
 *
 * @returns {ConversationHeatMapResult}
 */
export const useConversationHeatMapData = (): ConversationHeatMapResult => {
  const { timePeriod } = useDateRangeContext()

  const cubeQuery = new CubeQueryBuilder({})
    .withName('ConversationHeatMapQuery')
    .withQueryName('ConversationHeatMapQuery')
    .withMeasures([CONVERSATION_FACTS_MEASURES.CONVERSATION_COUNT])
    .withDimensions([
      CONVERSATION_FACTS_DIMENSIONS.DAY_OF_WEEK,
      CONVERSATION_FACTS_DIMENSIONS.HOUR_OF_DAY,
    ])
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate()
    )
    .withOrder([
      [CONVERSATION_FACTS_DIMENSIONS.DAY_OF_WEEK, 'desc'],
      [CONVERSATION_FACTS_DIMENSIONS.HOUR_OF_DAY, 'asc'],
    ])
    .build()

  const { data, isLoading, isError } = useCubeQuery({
    query: cubeQuery,
  })

  const formatSeriesData = useCallback((data: ResultSet | undefined) => {
    const cubeSeries = data ? data.series() : []
    const seriesData = R.pathOr([], [0, 'series'], cubeSeries)
    const resolveHour = R.pipe(R.split(','), R.path([1]), Number)
    const resolveDay = R.pipe(R.split(','), R.path([0]))

    return seriesData.map((series: ConversationSeriesData) => {
      const day = DayToNumberMap[resolveDay(series.x) as string]
      const hour = resolveHour(series.x)
      const count = series.value
      return [day, hour, count]
    })
  }, [])

  const chartData = useMemo(() => {
    return data ? formatSeriesData(data) : []
  }, [data, formatSeriesData])

  const hasError = useMemo(() => !isLoading && isError, [isLoading, isError])

  const isEmpty = useMemo(
    () => !isLoading && isNilOrEmpty(chartData),
    [chartData, isLoading]
  )

  return useMemo(
    () => ({ chartData, isLoading, hasError, isEmpty }),
    [chartData, isLoading, hasError, isEmpty]
  )
}

export default useConversationHeatMapData
