import { isEmpty } from 'ramda'
import { connect } from 'react-redux'

import { DashboardMetrics } from 'components'
import { withDateRangeContext } from 'contexts/dateRange/dateRangeProvider'
import { hasGates } from 'utils/accessUtils'

const REVENUE_METRICS_GATE = 'revenue_metrics'
const HAS_SALESFORCE_GATE = 'premium_team'
const SALESFORCE_REVENUE_ORG_PROPERTY = 'salesforceRevenueMetricsEnabled'

const mapStateToProps = (state) => {
  const hasRevenueMetrics = hasGates(REVENUE_METRICS_GATE)

  const isLoadingOrgData =
    isEmpty(state.org.properties) || isEmpty(state.org.users)

  return {
    isLoading: isLoadingOrgData,
    isFreeUser: !hasGates('premium_drift'),
    currentRevenueTotals: state.revenueMetrics.totals,
    hasSalesforceInPlan: hasGates(HAS_SALESFORCE_GATE),
    revenueMetricsLoaded: state.revenueMetrics.isLoaded || false,
    hasSalesforceConnected: state.revenueMetrics.salesforceConnected,
    isRevenueOptedIn:
      state.org.properties[SALESFORCE_REVENUE_ORG_PROPERTY] || false,
    hasRevenueMetrics: hasRevenueMetrics,
  }
}

export default connect(mapStateToProps)(withDateRangeContext(DashboardMetrics))
