import { CubeQuery } from 'cube'
import moment from 'moment-timezone'
import { clone, mergeDeepRight } from 'ramda'

import { isNilOrEmpty } from 'utils/ramda'

const supportedTimezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'Europe/London',
]

const now = Date.now()

const supportedTimezoneOffsetMap = supportedTimezones.map((timezone) => {
  const utcOffset = moment.tz.zone(timezone).utcOffset(now)
  return {
    timezone,
    offset: utcOffset,
  }
})

export const resolvePreaggregatedTimezone = () => {
  const guessedTimezone = moment.tz.guess()

  if (supportedTimezones.includes(guessedTimezone)) {
    return guessedTimezone
  }

  const guessedTimezoneUtcOffset = moment.tz
    .zone(guessedTimezone)
    .utcOffset(now)
  const closestTimezone = supportedTimezoneOffsetMap.reduce((acc, curr) => {
    if (acc === null || acc === undefined) {
      return curr
    }

    if (
      Math.abs(curr.offset - guessedTimezoneUtcOffset) <
      Math.abs(acc.offset - guessedTimezoneUtcOffset)
    ) {
      return curr
    }

    return acc
  })

  return closestTimezone.timezone
}

export const formatCubeQueryWithDateRange = (
  query: CubeQuery['query'],
  startDate: string,
  endDate: string
) => {
  const clonedQuery = clone(query)

  if (!isNilOrEmpty(clonedQuery.timeDimensions) && startDate && endDate) {
    clonedQuery.timeDimensions = clonedQuery.timeDimensions.map(
      (timeDimension: any) => {
        return mergeDeepRight(timeDimension, {
          dateRange: [
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
          ],
        })
      }
    )
    return {
      ...clonedQuery,
      timezone: resolvePreaggregatedTimezone(),
    }
  }
  return query
}
