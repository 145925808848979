import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_MEASURES,
} from 'cube/constants'

import { type CubeQuery } from '@driftt/dds.reports'

/**
 * Base cube query for conversation count.
 */
export const ConversationCount: CubeQuery = {
  name: 'ConversationCount',
  queryName: 'ConversationCount',
  query: {
    measures: [CONVERSATION_FACTS_MEASURES.CONVERSATION_COUNT],
    order: [[CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT, 'desc']],
  },
}
