import { connect } from 'react-redux'

import DiscoverHome from 'layouts/DiscoverHome'

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    name: state.user.name,
  }
}

export default connect(mapStateToProps)(DiscoverHome)
