export const VIEWED_MM_MODAL_PROPERTY = 'viewedMarchMMModalOnDash'
export const VIEWED_APRIL_MM_MODAL_PROPERTY = 'viewedAprilMMModalOnDash'
export const VIEWED_JUNE_MM_MODAL_PROPERTY = 'viewedJuneMMModalOnDash'
export const VIEWED_JULY_MM_MODAL_PROPERTY = 'viewedJulyMMModalOnDash'

export const VIEWED_OCTOBER_MM_MODAL_PROPERTY = 'viewedOctoberMMModalOnDash'
export const VIEWED_NOVEMBER_MM_MODAL_PROPERTY = 'viewedNovemberMMModalOnDash'

export const NEVER_SHOW_REVENUE_OPT_IN = 'never-show-revenue-opt-in-home'
export const SALES_BANNER_DISMISS_SETTING = 'sales_home_banner_expiration_date'
