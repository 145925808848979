import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_ZONE_DIMENSIONS,
  DRIFT_CHAT_END_USERS_DIMENSIONS,
} from 'cube/constants'

import type { CubeQuery } from '@driftt/dds.reports'

export const OverviewConversationDrillDownQuery: CubeQuery = {
  name: 'OverviewConversationDrillDownQuery',
  query: {
    measures: [],
    dimensions: [
      CONVERSATION_FACTS_ZONE_DIMENSIONS.CONVERSATION_CREATED_AT,
      CONVERSATION_FACTS_DIMENSIONS.HAS_EMAIL_CAPTURED,
      CONVERSATION_FACTS_DIMENSIONS.CQL_SCORE,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_ID,
      DRIFT_CHAT_END_USERS_DIMENSIONS.EMAIL,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_STATUS,
    ],
    order: [
      [CONVERSATION_FACTS_ZONE_DIMENSIONS.CONVERSATION_CREATED_AT, 'desc'],
    ],
  },
}
