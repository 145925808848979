import { connect } from 'react-redux'
import { userSettingsSelector } from 'selectors/User'

import { access } from '@driftt/traffic-control'

import App from 'layouts/App'
import {
  isMarketerUser,
  isPlaybooksUser,
  isSalesManagerUser,
  isSalesRepUser,
  isServiceUser,
  userHasFullDashboardAccess,
} from 'utils/accessUtils'

const mapStateToProps = (state) => {
  return {
    userHasFullDashboardAccess: userHasFullDashboardAccess(),
    isSalesRepUser: isSalesRepUser(),
    isSalesManagerUser: isSalesManagerUser(),
    isMarketerUser: isMarketerUser(),
    isServiceUser: isServiceUser(),
    isPlaybooksUser: isPlaybooksUser(),
    isRbacUser: access.isRbacUser(),
    userEmail: state.user.email,
    isJITProvisioned: userSettingsSelector(state).IS_JIT_PROVISIONED_USER,
    driftUser: state.user,
  }
}

export default connect(mapStateToProps)(App)
