import { ResultSet } from '@cubejs-client/core'
import moment from 'moment'

import { isNilOrEmpty } from 'utils/ramda'

// Cube question: can we still format series results when there's no data for given time period? (empty state)
export const formatSeriesData = (resultSetByDay: ResultSet) => {
  if (isNilOrEmpty(resultSetByDay)) {
    return []
  }

  const seriesData = resultSetByDay.series()
  return isNilOrEmpty(seriesData)
    ? []
    : seriesData[0].series.map((value) => [
        moment(value.x).format('YYYY-MM-DD'),
        value.value,
      ])
}
