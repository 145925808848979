const integrationsReducer = (state = {}, action) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'RECIEVE_INTEGRATION_STATES':
      return action.integrations

    default:
      return state
  }
}

export default integrationsReducer
