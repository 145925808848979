import conversationReportsReducer from './ConversationReports'
import integrationsReducer from './Integrations'
import meetingReportsReducer from './MeetingReports'
import revenueMetricsReducer from './RevenueMetrics'
import schedulingReducer from './Scheduling'
import scopesReducer from './Scopes'

export const rootReducer = [
  {
    key: 'conversationReports',
    reducer: conversationReportsReducer,
  },
  {
    key: 'meetingReports',
    reducer: meetingReportsReducer,
  },
  {
    key: 'revenueMetrics',
    reducer: revenueMetricsReducer,
  },
  {
    key: 'integrations',
    reducer: integrationsReducer,
  },
  {
    key: 'scopes',
    reducer: scopesReducer,
  },
  {
    key: 'scheduling',
    reducer: schedulingReducer,
  },
]
