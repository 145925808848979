import React from 'react'

import { each as _each, findIndex as _findIndex } from 'lodash'

const OPPORTUNITIES_TOOLTIP = (
  <div>
    Opportunities on accounts that
    <br /> have Drift activity associated with them
  </div>
)
const PIPELINE_TOOLTIP = (
  <div>
    Dollar amount of any opportunities
    <br /> that have Drift activity associated with them
  </div>
)
const CLOSED_WON_TOOLTIP = (
  <div>
    Closed won opportunities on accounts <br /> that have Drift activity
    associated with it
  </div>
)

const CONVERSATIONS_TOOTIP = (
  <div>
    This is the number of <br /> conversations created in Drift
  </div>
)
const EMAILS_CAPTURED_TOOLTIP = (
  <div>
    This is the number of <br /> new leads created in Drift
  </div>
)
const CQL_TOOLTIP = <div>These are Conversation Qualified Leads</div>
const MEETINGS_BOOKED_TOOLTIP = (
  <div>
    This is the number of meetings <br /> that were booked in Drift
  </div>
)
const EMAILS_SENT_TOOLTIP = (
  <div>
    This is the number of <br /> email sequences you've sent this week
  </div>
)

const SALES_REVENUE_TOOLTIP = (
  <div>
    Revenue influenced by Drift <br /> is closed won on accounts <br /> that
    have Drift activity associated with it
  </div>
)

export const RevenueMetricNames = Object.freeze({
  opportunityCount: {
    displayName: 'Opportunities',
    tooltipText: OPPORTUNITIES_TOOLTIP,
  },
  opportunityAmount: {
    displayName: 'Pipeline influenced',
    tooltipText: PIPELINE_TOOLTIP,
  },
  closedAmount: {
    displayName: 'Closed won',
    tooltipText: CLOSED_WON_TOOLTIP,
  },
})

export const OverviewMetricNames = Object.freeze({
  conversations: {
    displayName: 'Conversations',
    tooltipText: CONVERSATIONS_TOOTIP,
  },
  emailsCaptured: {
    displayName: 'Emails captured',
    tooltipText: EMAILS_CAPTURED_TOOLTIP,
  },
  cqls: {
    displayName: 'CQLs',
    tooltipText: CQL_TOOLTIP,
  },
})

export const SalesPerformanceMetricNames = {
  EMAILS_SENT: {
    displayName: 'Emails Sent',
    tooltipText: EMAILS_SENT_TOOLTIP,
    format: '0,0',
  },
  MEETINGS_BOOKED: {
    displayName: 'Meetings booked',
    tooltipText: MEETINGS_BOOKED_TOOLTIP,
    format: '0,0',
  },
  CONVERSATIONS: {
    displayName: 'Conversations',
    tooltipText: CONVERSATIONS_TOOTIP,
    format: '0,0',
  },
  REVENUE_INFLUENCED: {
    displayName: 'Revenue influenced by Drift',
    tooltipText: SALES_REVENUE_TOOLTIP,
    format: '($0a)',
  },
}

const getDimensionIndexByName = (payload, dimensionName) => {
  const dimensions = payload.metadata.dimensions

  if (!dimensions || dimensions.length === 0) {
    return -1
  }

  return _findIndex(dimensions, (dimension) => dimension.name === dimensionName)
}

const getMetricIndexByName = (payload, metricName) => {
  const seriesMetrics = payload.metadata.metrics

  if (!seriesMetrics || seriesMetrics.length === 0) {
    return -1
  }

  return _findIndex(seriesMetrics, (metric) => metric.name === metricName)
}

export const getTotalForMetric = (payload, metric) => {
  if (!payload) {
    return 0
  }

  const metricIndex = getMetricIndexByName(payload, metric)
  const dataRows = payload.data.rows

  if (metricIndex < 0) {
    return 0
  }

  return dataRows
    .map((row) => row.metrics[metricIndex])
    .reduce((prev, next) => prev + next)
}

const getSeriesDataForMetric = (payload, metric) => {
  if (!payload) {
    return []
  }

  const dimensionIndex = getDimensionIndexByName(payload, 'date')
  const metricIndex = getMetricIndexByName(payload, metric)
  const dataRows = payload.data.rows

  if (dimensionIndex < 0 || metricIndex < 0) {
    return []
  }

  const dataMap = dataRows.map((row) => [
    row.dimensions[dimensionIndex],
    row.metrics[metricIndex],
  ])

  const uniqueSeriesData = []
  _each(dataMap, (item) => {
    if (uniqueSeriesData.includes(item[0])) {
      uniqueSeriesData[1] = uniqueSeriesData[1] + item[0]
    } else {
      uniqueSeriesData.push(item)
    }
  })

  return uniqueSeriesData
}

export const getSeriesData = (payload) => {
  const dataRows = payload.data.rows

  const dataMap = dataRows.map((row) => [row.dimensions[0], row.metrics[0]])

  const uniqueSeriesData = []
  _each(dataMap, (item) => {
    if (uniqueSeriesData.includes(item[0])) {
      uniqueSeriesData[1] = uniqueSeriesData[1] + item[0]
    } else {
      uniqueSeriesData.push(item)
    }
  })

  return uniqueSeriesData
}

export const getSeriesDataForMetrics = (payload, metrics) => {
  const seriesData = {}

  _each(metrics, (metric) => {
    const series = getSeriesDataForMetric(payload, metric)
    seriesData[metric] = series
  })

  return seriesData
}

export const getTotalsForMetrics = (payload, metrics) => {
  const totals = {}
  _each(metrics, (metric) => {
    totals[metric] = getTotalForMetric(payload, metric)
  })

  return totals
}
