import { useMemo } from 'react'

import {
  CubeSchemaTables,
  MEETING_FACTS_DIMENSIONS,
} from 'cube/constants/QueryConstants'
import { MeetingsBookedWithBaseQuery } from 'cube/queries'

import {
  CubeQueryBuilder,
  resultSetAsTableData,
  useCubeQuery,
} from '@driftt/dds.reports'

import { useDateRangeContext } from 'contexts/dateRange'

import { MeetingBookedWithRow } from './MeetingBookedWith.types'

type MeetingBookedMetricRow = {
  [CubeSchemaTables.MEETINGS]: {
    count: number
  }
  [CubeSchemaTables.APP_USERS]: {
    displayName: string
    status: string
    avatarUrl: string
    userId: number
  }
}
export interface MeetingBookedWithResult {
  tableData: MeetingBookedWithRow[]
  isLoadingTableData: boolean
  isErrorFetchingTableData: boolean
}

/***
 * Hook that uses cube to fetch user meeting booking data.
 *
 * @returns {MeetingBookedWithResult}
 */
export const useConversationsStartedFrom = () => {
  const { timePeriod } = useDateRangeContext()

  const meetingsBookedWithCubeQuery = new CubeQueryBuilder({
    query: MeetingsBookedWithBaseQuery,
  })
    .withinDateRange(
      MEETING_FACTS_DIMENSIONS.SCHEDULED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate()
    )
    .withLimit(5)
    .build()

  const {
    data,
    isLoading: isLoadingTableData,
    isError: isErrorFetchingTableData,
  } = useCubeQuery({
    query: meetingsBookedWithCubeQuery,
  })

  const tableData: MeetingBookedWithRow[] = useMemo(() => {
    const tableRows = resultSetAsTableData(data) as MeetingBookedMetricRow[]

    return tableRows.map((row) => ({
      displayName: row[CubeSchemaTables.APP_USERS].displayName,
      avatarUrl: row[CubeSchemaTables.APP_USERS].avatarUrl,
      status: row[CubeSchemaTables.APP_USERS].status,
      count: row[CubeSchemaTables.MEETINGS].count,
      userId: row[CubeSchemaTables.APP_USERS].userId,
    }))
  }, [data])

  return useMemo(
    () => ({ tableData, isLoadingTableData, isErrorFetchingTableData }),
    [tableData, isLoadingTableData, isErrorFetchingTableData]
  )
}

export default useConversationsStartedFrom
