import React from 'react'

import { compactInteger, formatNumber } from 'humanize-plus'
import moment from 'moment'
import { defaultTo } from 'ramda'

import { Div, H4, Li, Small, Strong, Ul } from '@driftt/tide-core'

const formatCurrencyUnrounded = (value) => {
  const decimalPlaces = value === 0 ? 0 : 2
  return formatNumber(value, decimalPlaces)
}

const TypeFormats = {
  RAW_HUMANIZED: (value) => formatNumber(value),
  FIXED: (value) => compactInteger(value, 1),
  DATETIME: (value) => moment(value).format('M/D'),
  DATE: (value) => moment(value).format('YYYY-MM-DD'),
  CURRENCY: (value) => `$${compactInteger(value, 1)}`,
  CURRENCY_UNROUNDED: (value) => `$${formatCurrencyUnrounded(value)}`,
  PERCENT: (value) => `${Math.round(value) || 0}%`,
  CONVERSIONRATE: (value) => `${Math.round(value * 100) / 100 || 0}%`,
}

const ValueFormatter = ({ value, name }) => {
  const valueToType = {
    openOpportunities: 'CURRENCY',
    closedOpportunities: 'CURRENCY',
    createdOpportunities: 'FIXED',
    opportunityCount: 'FIXED',
    opportunityAmount: 'CURRENCY',

    trendopenOpportunities: 'CURRENCY',
    trendclosedOpportunities: 'CURRENCY',
    trendcreatedOpportunities: 'FIXED',

    meetingsBooked: 'FIXED',
    conversationsCreated: 'FIXED',
    conversations: 'FIXED',
    leadsCreated: 'FIXED',
    engaged: 'FIXED',

    trendmeetingsBooked: 'FIXED',
    trendconversationsCreated: 'FIXED',
    trendleadsCreated: 'FIXED',
    closedAmount: 'CURRENCY',
  }

  const valueOrDefault = value || 0
  const typeValue = TypeFormats[valueToType[name]]

  return typeValue
    ? typeValue(valueOrDefault)
    : TypeFormats['FIXED'](valueOrDefault)
}

const FormatSeries = (series) => {
  return series.map((data = []) => {
    const formattedValueSet = Object.keys(data).map((key) => {
      return { [key]: data[key].value }
    })
    return Object.assign(...formattedValueSet)
  })
}

const FormatDateTick = (value) => moment(value).format('MM/DD')

const CustomTooltip = ({ payload, active, label }) => {
  if (!active || !payload) return null
  const hasNonTrendValue = (value) =>
    value.includes('trend') &&
    !!payload.filter((entry) => entry.name === value.replace('trend', ''))[0]
      .value
  return (
    <Div className="drift-area-chart-tooltip playbooks-tooltip">
      <H4>{FormatDateTick(label)}</H4>
      <Ul>
        {payload.map((entry, index) => {
          if (
            !entry.name ||
            !entry.name.length ||
            hasNonTrendValue(entry.name) ||
            !entry.value
          )
            return null
          return (
            <Li key={index}>
              <Strong>{ValueFormatter(entry)}</Strong>
              <Small>{entry.name}</Small>
            </Li>
          )
        })}
      </Ul>
    </Div>
  )
}

const TooltipValueFormatter = ({ value, name }) => {
  const valueToType = {
    openOpportunities: 'CURRENCY_UNROUNDED',
    closedOpportunities: 'CURRENCY_UNROUNDED',
    trendopenOpportunities: 'CURRENCY_UNROUNDED',
    trendclosedOpportunities: 'CURRENCY_UNROUNDED',
    closedAmount: 'CURRENCY_UNROUNDED',
    opportunityAmount: 'CURRENCY_UNROUNDED',
  }

  const typeFormat = defaultTo('RAW_HUMANIZED')(valueToType[name])
  return TypeFormats[typeFormat](value)
}

export {
  TypeFormats,
  FormatSeries,
  ValueFormatter,
  FormatDateTick,
  CustomTooltip,
  TooltipValueFormatter,
}

export const getHumanizedDurationMilliseconds = (duration) => {
  const momentDuration = moment.duration(duration, 'milliseconds')
  const months = momentDuration.months()
  const days = momentDuration.days()
  const hours = momentDuration.hours()
  const minutes = momentDuration.minutes()
  const seconds = momentDuration.seconds()
  if (months) {
    return `${months}m ${days}d ${hours}h ${minutes}m ${seconds}s`
  }
  if (days) {
    return `${days}d ${hours}h ${minutes}m ${seconds}s`
  }
  if (hours) {
    return `${hours}h ${minutes}m ${seconds}s`
  }
  if (minutes) {
    return `${minutes}m ${seconds}s`
  }
  return seconds ? `${seconds}s` : `0s`
}
