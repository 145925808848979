import { createContext, useContext } from 'react'

import moment, { type Moment } from 'moment'

export type DateRangeContextType = {
  legendText: {
    series: string
    previousSeries: string
  }
  onChangeTimePeriod: (series: string, previousSeries: string) => void
  timePeriod: {
    endDate: Moment
    prevEnd: Moment
    startDate: Moment
    prevStart: Moment
  }
}

const DateRangeContext = createContext<DateRangeContextType>({
  legendText: {
    series: '',
    previousSeries: '',
  },
  onChangeTimePeriod: () => null,
  timePeriod: {
    endDate: moment(),
    prevEnd: moment(),
    startDate: moment(),
    prevStart: moment(),
  },
})

export const useDateRangeContext = () => useContext(DateRangeContext)

export default DateRangeContext
