import React from 'react'

import PropTypes from 'prop-types'

import { Flex } from '@driftt/dds'
import { A, ColorWay, Div } from '@driftt/tide-core'
import { Icon } from '@driftt/tide-icons'

import { hasGates } from 'utils/accessUtils'
import { newConversation } from 'utils/conversationUtils'

import { NO_DATA_TEXT } from '../Reporting/Charts/constants'

import './style.css'

const ERROR_HEADER = 'Something went wrong'
const ERROR_DESCRIPTION = 'We’re unable to retrieve data for this report.'

const renderErrorCTAs = (onRetryClick) => {
  const contactAction = hasGates('premium_drift') ? (
    `${onRetryClick ? 'c' : 'C'}ontact your CSM for assistance`
  ) : (
    <A onClick={newConversation}>{onRetryClick ? 'c' : 'C'}hat with us</A>
  )

  if (onRetryClick) {
    return (
      <React.Fragment>
        <A onClick={onRetryClick}>Refresh</A> or {contactAction}
      </React.Fragment>
    )
  }

  return contactAction
}

const MetricsNoDataState = ({
  header,
  description,
  imageSrc,
  imageWidth = 'auto',
  imageHeight = 'auto',
}) => (
  <Div className="revenue-no-data-container">
    {imageSrc ? (
      <img
        className="revenue-no-data-image"
        src={imageSrc}
        alt=""
        style={{ width: imageWidth, height: imageHeight }}
      />
    ) : (
      <ColorWay
        className="revenue-no-data-image"
        type={Math.floor(Math.random() * 5)}
      >
        <Icon name="cw-empty-search" />
      </ColorWay>
    )}
    {header && <Div className="revenue-no-data-header">{header}</Div>}
    {description && (
      <Div className="revenue-no-data-text revenue-no-data-description">
        {description}
      </Div>
    )}
  </Div>
)

MetricsNoDataState.propTypes = {
  header: PropTypes.string,
  description: PropTypes.node,
  imageSrc: PropTypes.string,
  imageHeight: PropTypes.any,
  imageWidth: PropTypes.any,
  onRetryClick: PropTypes.func,
}

export default MetricsNoDataState

export const DefaultMetricsNoDataState = ({ description }) => (
  <MetricsNoDataState header={NO_DATA_TEXT} description={description} />
)

export const MetricsErrorState = ({ onRetryClick = () => null }) => (
  <MetricsNoDataState
    header={ERROR_HEADER}
    description={
      <React.Fragment>
        {ERROR_DESCRIPTION}
        <br />
        {renderErrorCTAs(onRetryClick)}
      </React.Fragment>
    }
  />
)

export const MetricsError = () => (
  <Flex fullWidth fullHeight justifyContent={'center'} alignItems={'center'}>
    <MetricsErrorState onRetryClick={() => null} />
  </Flex>
)
