import React, { useMemo } from 'react'

import { CubeSchemaTables } from 'cube/constants'
import { capitalize } from 'humanize-plus'

import {
  type TableColumn,
  Flex,
  Link,
  StatusBadge,
  Table,
  UserAvatar,
} from '@driftt/dds'
import { TypeFormats, useDrillDownDataModalContext } from '@driftt/dds.reports'

import config from 'config'

type MeetingDrillDownMetric = {
  [CubeSchemaTables.END_USERS]: {
    displayName: string
  }
  [CubeSchemaTables.APP_USERS]: {
    displayName: string
  }
  [CubeSchemaTables.MEETINGS]: {
    schedulingEventSlotStartAt: number
    meetingSource: string
    status: string
    conversationId: number
  }
}

type MeetingDrillDownMetricRow = {
  appUserDisplayName: string
  endUserDisplayName: string
  schedulingEventSlotStartAt: number
  meetingSource: string
  status: string
  conversationId: number
}

const SOURCE_HUMANIZED_TRANSLATION = {
  CALENDAR_DROP: 'Calendar share',
  EMAIL_DROP: 'Email extension',
  PLAYBOOK_DROP: 'Playbook',
  PROFILE_DROP: 'Drift Profile',
  PROFILE_LINK: 'Drift Profile',
  RESCHEDULE: 'Reschedule',
  SEQUENCE_DROP: 'Sequence email',
  NONE: 'None',
  FALLBACK_EMAIL: 'Fallback email',
  VIDEO_MEETING: 'Video meeting',
  PLAYBOOK_DROP_TEAM: 'Playbook',
  SITE_CONCIERGE: 'Site Concierge',
} as const

const defaultColumns: TableColumn<MeetingDrillDownMetricRow>[] = [
  {
    id: 'endUserDisplayName',
    header: 'Name',
    value: 'endUserDisplayName',
    cell: ({ endUserDisplayName }) => (
      <Flex alignItems="center">
        <UserAvatar size={'small'} name={endUserDisplayName} />
        {endUserDisplayName}
      </Flex>
    ),
  },
  {
    id: 'appUserDisplayName',
    header: 'Booked with',
    value: 'appUserDisplayName',
    cell: ({ appUserDisplayName }) => <Flex>{appUserDisplayName}</Flex>,
  },
  {
    id: 'schedulingEventSlotStartAt',
    header: 'Booked for',
    value: 'schedulingEventSlotStartAt',
    cell: ({ schedulingEventSlotStartAt }) =>
      TypeFormats.DATE(new Date(schedulingEventSlotStartAt)),
    disableSort: true,
  },
  {
    id: 'meetingSource',
    value: 'meetingSource',
    header: 'Source',
    cell: ({ meetingSource }) => (
      <Flex>{SOURCE_HUMANIZED_TRANSLATION[meetingSource as keyof object]}</Flex>
    ),
    disableSort: true,
  },
  {
    id: 'status',
    value: 'status',
    header: 'Status',
    cell: ({ status }) => {
      const variant =
        status === 'ACTIVE'
          ? 'success'
          : status === 'CANCELLED'
          ? 'error'
          : 'warning'

      return (
        <StatusBadge variant={variant} size="medium">
          {status === 'ACTIVE' ? 'Scheduled' : capitalize(status.toLowerCase())}
        </StatusBadge>
      )
    },
    disableSort: true,
  },
  {
    id: 'conversationId',
    value: 'conversationId',
    header: 'Chat URL',
    cell: ({ conversationId }) =>
      conversationId ? (
        <Link
          variant="object"
          external={true}
          href={`${config.APP_URL}/conversations/${conversationId}`}
        >
          View Chat
        </Link>
      ) : (
        <Flex>-</Flex>
      ),
    disableSort: true,
  },
]

export const MeetingDrillDownDataTable = () => {
  const { tableData, isLoading } = useDrillDownDataModalContext()

  const renderableTableRows = useMemo(() => {
    const tableRows = tableData as MeetingDrillDownMetric[]

    return tableRows.map((row: MeetingDrillDownMetric) => {
      return {
        endUserDisplayName: row[CubeSchemaTables.END_USERS].displayName,
        appUserDisplayName: row[CubeSchemaTables.APP_USERS].displayName,
        ...row[CubeSchemaTables.MEETINGS],
      }
    }) as MeetingDrillDownMetricRow[]
  }, [tableData])

  return (
    <Table
      rows={renderableTableRows}
      columns={defaultColumns}
      isLoading={isLoading}
      disableBoxShadow
    />
  )
}
