import React, { useMemo } from 'react'

import Highcharts from 'highcharts'
import heatmap from 'highcharts/modules/heatmap'
import HighchartsReact from 'highcharts-react-official'
import { mergeDeepRight } from 'ramda'

import { Skeleton } from '@driftt/dds'
import { ChartEmptyState } from '@driftt/dds.reports'

import { HeatMapEmptyState } from './HeatMap.assets'
import OPTIONS from './HeatMap.options'

import './style.css'

export type HeatMapProps = {
  series: number[][]
  isLoading: boolean
  isEmpty: boolean
  onPointClick: (pointerEvent: Highcharts.PointClickEventObject) => void
}

heatmap(Highcharts)

export const HeatMap = ({
  series,
  isLoading = true,
  isEmpty = false,
  onPointClick,
}: HeatMapProps) => {
  const options: Highcharts.Options = useMemo<Highcharts.Options>(
    () =>
      mergeDeepRight(OPTIONS, {
        series: [
          {
            borderWidth: 3,
            borderColor: '#FFFFFF',
            data: series,
            cursor: 'pointer',
            point: {
              events: {
                click: onPointClick,
              },
            },
          },
        ],
      }) as Highcharts.Options,
    [series, onPointClick]
  )

  return (
    <Skeleton isLoading={isLoading} height={375}>
      {isEmpty ? (
        <ChartEmptyState>
          <HeatMapEmptyState />
        </ChartEmptyState>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </Skeleton>
  )
}
