import { useMemo } from 'react'

import { type CubeQuery, useCubeQuery } from '@driftt/dds.reports'

export type UseQueryCompareDatesProps = {
  query: CubeQuery
  previousQuery: CubeQuery
}

/**
 * Fetches data for a given query provided for both the current and previous timePeriods
 *
 * @param {Object} cubeQuery - Query for the current time period
 * @param {Object} previousQuery - Query for the previous time period
 */
export const useQueryCompareDates = ({
  query,
  previousQuery,
}: UseQueryCompareDatesProps) => {
  const {
    isLoading: isLoadingCurrent,
    isError: errorCurrent,
    data: resultSetCurrent,
  } = useCubeQuery({
    query: query,
  })

  const {
    isLoading: isLoadingPrevious,
    isError: errorPrevious,
    data: resultSetPrevious,
  } = useCubeQuery({
    query: previousQuery,
  })

  const isLoading = isLoadingCurrent || isLoadingPrevious
  const isError = errorCurrent || errorPrevious

  return useMemo(
    () => ({
      isLoading,
      isError,
      data: {
        current: resultSetCurrent,
        previous: resultSetPrevious,
      },
    }),
    [isLoading, isError, resultSetCurrent, resultSetPrevious]
  )
}
