import { useMemo } from 'react'

import { ResultSet } from '@cubejs-client/core'
import {
  CONVERSATION_FACTS_DIMENSIONS,
  ConversationCount,
  CQLCount,
  EmailsCapturedCount,
  useQueryCompareDates,
} from 'cube'
import { type Moment } from 'moment'

import { CubeQueryBuilder } from '@driftt/dds.reports'

import { DRIFT_OVERVIEW_METRIC_TYPES } from '../constants'

const useDriftOverviewMetrics = ({
  timePeriod,
}: {
  timePeriod: {
    startDate: Moment
    endDate: Moment
    prevStart: Moment
    prevEnd: Moment
  }
}): {
  isLoading: boolean
  isError: boolean
  data: Record<
    typeof DRIFT_OVERVIEW_METRIC_TYPES[keyof typeof DRIFT_OVERVIEW_METRIC_TYPES],
    {
      total: {
        current?: ResultSet
        previous?: ResultSet
      }
      byDay: {
        current?: ResultSet
        previous?: ResultSet
      }
    }
  >
} => {
  // Conversations
  const conversationsCurrentQuery = new CubeQueryBuilder({
    query: ConversationCount,
  })
    .withName('ConversationCount')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate()
    )
    .build()

  const conversationsPreviousQuery = new CubeQueryBuilder({
    query: ConversationCount,
  })
    .withName('ConversationCountPrevious')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate()
    )
    .build()

  const {
    isLoading: isLoadingConversations,
    isError: isErrorConversations,
    data: conversationData,
  } = useQueryCompareDates({
    query: conversationsCurrentQuery,
    previousQuery: conversationsPreviousQuery,
  })

  // Conversations by day
  const conversationsByDayQuery = new CubeQueryBuilder({
    query: ConversationCount,
  })
    .withName('ConversationCountByDay')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate(),
      'day'
    )
    .build()

  const conversationsByDayQueryPrevious = new CubeQueryBuilder({
    query: ConversationCount,
  })
    .withName('ConversationCountByDayPrevious')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate(),
      'day'
    )
    .build()

  const {
    isLoading: isLoadingConversationsByDay,
    isError: isErrorConversationsByDay,
    data: conversationDataByDay,
  } = useQueryCompareDates({
    query: conversationsByDayQuery,
    previousQuery: conversationsByDayQueryPrevious,
  })

  // Emails Captured
  const emailsCapturedQuery = new CubeQueryBuilder({
    query: EmailsCapturedCount,
  })
    .withName('EmailsCapturedQuery')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate()
    )
    .build()

  const emailsCapturedQueryPrevious = new CubeQueryBuilder({
    query: EmailsCapturedCount,
  })
    .withName('EmailsCapturedQueryPrevious')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate()
    )
    .build()

  const {
    isLoading: isLoadingEmailsCaptured,
    isError: isErrorEmailsCaptured,
    data: emailsCapturedData,
  } = useQueryCompareDates({
    query: emailsCapturedQuery,
    previousQuery: emailsCapturedQueryPrevious,
  })

  // Emails Captured by day
  const emailsCapturedByDayQuery = new CubeQueryBuilder({
    query: EmailsCapturedCount,
  })
    .withName('EmailsCapturedByDay')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate(),
      'day'
    )
    .build()

  const emailsCapturedByDayQueryPrevious = new CubeQueryBuilder({
    query: EmailsCapturedCount,
  })
    .withName('EmailsCapturedtByDayPrevious')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate(),
      'day'
    )
    .build()

  const {
    isLoading: isLoadingEmailsCapturedByDay,
    isError: isErrorEmailsCapturedByDay,
    data: emailsCapturedDataByDay,
  } = useQueryCompareDates({
    query: emailsCapturedByDayQuery,
    previousQuery: emailsCapturedByDayQueryPrevious,
  })

  // CQLs
  const CQLCountQuery = new CubeQueryBuilder({
    query: CQLCount,
  })
    .withName('CQLCount')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate()
    )
    .build()

  const CQLCountQueryPrevious = new CubeQueryBuilder({
    query: CQLCount,
  })
    .withName('CQLCountPrevious')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate()
    )
    .build()

  const {
    isLoading: isLoadingCqls,
    isError: isErrorCqls,
    data: currentCqlsData,
  } = useQueryCompareDates({
    query: CQLCountQuery,
    previousQuery: CQLCountQueryPrevious,
  })

  // CQLs by day
  const CQLsByDayQuery = new CubeQueryBuilder({
    query: CQLCount,
  })
    .withName('CQLCountByDay')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate(),
      'day'
    )
    .build()

  const CQLsByDayQueryPrevious = new CubeQueryBuilder({
    query: CQLCount,
  })
    .withName('CQLCountByDayPrevious')
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.prevStart.toDate(),
      timePeriod.prevEnd.toDate(),
      'day'
    )
    .build()

  const {
    isLoading: isLoadingCqlsByDay,
    isError: isErrorCqlsByDay,
    data: currentCqlsDataByDay,
  } = useQueryCompareDates({
    query: CQLsByDayQuery,
    previousQuery: CQLsByDayQueryPrevious,
  })

  const isLoading =
    isLoadingConversations ||
    isLoadingConversationsByDay ||
    isLoadingEmailsCaptured ||
    isLoadingEmailsCapturedByDay ||
    isLoadingCqls ||
    isLoadingCqlsByDay

  const isError =
    isErrorConversations ||
    isErrorConversationsByDay ||
    isErrorEmailsCaptured ||
    isErrorEmailsCapturedByDay ||
    isErrorCqls ||
    isErrorCqlsByDay

  return useMemo(
    () => ({
      isLoading,
      isError,
      data: {
        [DRIFT_OVERVIEW_METRIC_TYPES.CONVERSATIONS]: {
          total: conversationData,
          byDay: conversationDataByDay,
        },
        [DRIFT_OVERVIEW_METRIC_TYPES.EMAILS_CAPTURED]: {
          total: emailsCapturedData,
          byDay: emailsCapturedDataByDay,
        },
        [DRIFT_OVERVIEW_METRIC_TYPES.CQLS]: {
          total: currentCqlsData,
          byDay: currentCqlsDataByDay,
        },
      },
    }),
    [
      isLoading,
      isError,
      conversationDataByDay,
      conversationData,
      emailsCapturedData,
      emailsCapturedDataByDay,
      currentCqlsData,
      currentCqlsDataByDay,
    ]
  )
}

export default useDriftOverviewMetrics
