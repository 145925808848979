import Highcharts from 'highcharts'

import { theme } from '@driftt/dds'

import { militaryToStandard } from '../ConversationHeatMapChart.helpers'

const options: Highcharts.Options = {
  title: {
    text: undefined,
  },
  chart: {
    type: 'heatmap',
    marginRight: 0,
    marginBottom: 80,
    plotBorderWidth: 0,
    borderColor: '#FFFFFF',
    height: '375px',
  },

  legend: {
    align: 'center',
    layout: 'horizontal',
    margin: 0,
    padding: 10,
    verticalAlign: 'bottom',
    y: 15,
    symbolHeight: 12,
    useHTML: true,
    itemStyle: {
      color: 'var(--drift-dds-colors-gray10)',
      fontSize: 'var(--drift-dds-fontSizes-h4)',
      fontWeight: '400',
      fontFamily: 'var(--drift-dds-fonts-primary)',
    },
  },

  tooltip: {
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: '#00000000',
    shadow: false,
    useHTML: true,
    formatter: function () {
      const { x, y, value } = this.point
      const { xAxis } = this.series
      return `
        <div class="tide-div highcharts-tooltip-container">
          <div class="dashboard-highcharts-tooltip-header">
            <span>New conversations</span>
          </div>
          <div class="dashboard-tooltip-label-container highcharts-tooltip-label-container">
            <span class="tide-span highcharts-tooltip-label">
              <p class="tide-paragraph tide-paragraph-1 highcharts-tooltip-label-key">
                ${xAxis.categories[x]}, ${militaryToStandard(y ?? 0)}
              </p>
              <p>${value}</p>
            </span>
          </div>
        </div>`
    },
  },
  xAxis: {
    categories: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    minorTickLength: 0,
    tickLength: 0,
    labels: {
      style: {
        color: 'var(--drift-dds-colors-gray10)',
        fontSize: 'var(--drift-dds-fontSizes-h4)',
        fontWeight: '400',
        fontFamily: 'var(--drift-dds-fonts-primary)',
      },
    },
  },

  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return militaryToStandard(Number(this.value))
      },
      style: {
        color: 'var(--drift-dds-colors-gray10)',
        fontSize: 'var(--drift-dds-fontSizes-h4)',
        fontWeight: '400',
        fontFamily: 'var(--drift-dds-fonts-primary)',
      },
    },
    minPadding: 0,
    maxPadding: 0,
    startOnTick: false,
    endOnTick: false,
    tickPositions: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
    min: 0,
    max: 23,
    reversed: true,
  },

  colorAxis: {
    min: 0,
    minColor: `${theme.colors.blue2.value}`,
    maxColor: `${theme.colors.blue8.value}`,
  },
  credits: {
    enabled: false,
  },
}

export default options
