import { useMemo } from 'react'

import {
  CONVERSATION_FACTS_DIMENSIONS,
  CubeSchemaTables,
} from 'cube/constants/QueryConstants'
import { ConversationsCreatedFromUrlBaseQuery } from 'cube/queries'

import {
  CubeQueryBuilder,
  resultSetAsTableData,
  useCubeQuery,
} from '@driftt/dds.reports'

import { useDateRangeContext } from 'contexts/dateRange'

import { ConversationStartedFromRow } from './ConversationStartedFromTable.types'

type ConversationStartedFromMetricRow = {
  [CubeSchemaTables.CONVERSATIONS]: {
    conversationCreatedFromUrl: string
    count: number
  }
}

export interface ConversationsStartedFromResult {
  tableData: ConversationStartedFromRow[]
  isLoadingTableData: boolean
  isErrorFetchingTableData: boolean
}

/***
 * Hook that uses cube to fetch conversations started from data.
 *
 * @returns {ConversationsStartedFromResult}
 */
export const useConversationsStartedFrom = () => {
  const { timePeriod } = useDateRangeContext()

  const conversationsCreatedFromUrlCubeQuery = new CubeQueryBuilder({
    query: ConversationsCreatedFromUrlBaseQuery,
  })
    .withFilters([
      {
        member: CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_FROM_URL,
        operator: 'set',
      },
    ])
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      timePeriod.startDate.toDate(),
      timePeriod.endDate.toDate()
    )
    .withLimit(10)
    .build()

  const {
    data,
    isLoading: isLoadingTableData,
    isError: isErrorFetchingTableData,
  } = useCubeQuery({
    query: conversationsCreatedFromUrlCubeQuery,
  })

  const tableData = useMemo(() => {
    const tableRows = resultSetAsTableData(
      data
    ) as ConversationStartedFromMetricRow[]

    return tableRows.map((row) => {
      return {
        conversationCreatedFromUrl:
          row[CubeSchemaTables.CONVERSATIONS].conversationCreatedFromUrl,
        count: row[CubeSchemaTables.CONVERSATIONS].count,
      }
    })
  }, [data])

  return useMemo(
    () => ({ tableData, isLoadingTableData, isErrorFetchingTableData }),
    [tableData, isLoadingTableData, isErrorFetchingTableData]
  )
}

export default useConversationsStartedFrom
