import React, { PureComponent } from 'react'

import CopyToClipboard from 'react-copy-to-clipboard'

import { Div, ToolTip } from '@driftt/tide-core'
import { Icon } from '@driftt/tide-icons'

import './style.css'

const DEFAULT_TOOLTIP_TEXT = 'Copy to clipboard'

class DriftProfileLink extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      tooltipText: DEFAULT_TOOLTIP_TEXT,
    }
  }

  onCopyClick = () => {
    this.setState({ tooltipText: 'Copied!' })
  }

  onMouseLeave = () => {
    this.setState({ tooltipText: DEFAULT_TOOLTIP_TEXT })
  }

  render() {
    const { tooltipText } = this.state
    const { userHandle } = this.props

    return (
      <Div className="drift-profile-handle">
        <Icon height={14} name="link-1" width={14} />
        <ToolTip flow="right" component={tooltipText}>
          <CopyToClipboard
            className="drift-profile-handle-text"
            text={userHandle}
            onCopy={this.onCopyClick}
            onMouseLeave={this.onMouseLeave}
          >
            <Div>{userHandle}</Div>
          </CopyToClipboard>
        </ToolTip>
      </Div>
    )
  }
}

export default DriftProfileLink
