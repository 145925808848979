import React, { useCallback } from 'react'

import { Button } from '@driftt/dds'

import { trackClickedEvent } from 'utils/trackingUtils'

type TrackableButtonProps = {
  /** The tracking name. This can simply be the button name in English. */
  trackingName: string
  /** The tracking page where the button is located. Defaults to window.location.pathname */
  trackingPage?: string
  onClick?: (...args: any[]) => void
  href?: string
} & React.ComponentProps<typeof Button>

const TrackableButton = ({
  trackingName,
  trackingPage = window.location.pathname,
  onClick,
  href,
  ...props
}: TrackableButtonProps) => {
  const onButtonClick = useCallback(
    (...args: any[]) => {
      if (trackingName) {
        trackClickedEvent(trackingPage, trackingName)
      } else {
        console.warn('Button missing tracking name in page', trackingPage)
      }
      if (href) {
        // Inspired by Segment trackLink: https://segment.com/docs/sources/website/analytics.js/#track-link
        window.setTimeout(() => {
          onClick && onClick(...args)
          window.location = href as unknown as Location
        }, 300)
      } else if (onClick) {
        onClick(...args)
      }
    },
    [onClick, trackingName, trackingPage, href]
  )

  return <Button {...props} variant="secondary" onClick={onButtonClick} />
}

export default TrackableButton
