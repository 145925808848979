import React from 'react'

import { isObject as _isObject } from 'lodash'

import { Box, CircleLoader, Flex, Header, Text, Tooltip } from '@driftt/dds'

import DeltaIcon from 'components/Reporting/DeltaIcon'
import { TypeFormats, ValueFormatter } from 'utils/formatUtils'

const renderTotals = (title, delta, formattedTotal, isLoaded, tooltipText) => (
  <>
    <Tooltip side="top" content={tooltipText}>
      <Text variant="subtitle2">{title}</Text>
    </Tooltip>
    <Box fullWidth>
      {!isLoaded && (
        <Flex justifyContent="center" fullWidth>
          <CircleLoader variant="dark" size="large" />
        </Flex>
      )}
      {isLoaded && (
        <Flex alignItems="flex-start">
          <Flex direction="column">
            <Header variant="metric">{formattedTotal}</Header>
            {delta !== 0 && (
              <Flex alignItems={'center'}>
                <DeltaIcon delta={delta} />
                <Text variant="metadata">{TypeFormats['PERCENT'](delta)}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </Box>
  </>
)

const MetricTotals = ({ name, title, data, tooltipText, isLoaded }) => {
  const total = _isObject(data.total) ? data.total.value : data.value
  const delta = _isObject(data.timePeriodDelta)
    ? data.timePeriodDelta.value
    : data.timePeriodDelta
  const formattedTotal = total
    ? ValueFormatter({ value: total, name: name })
    : 0

  return (
    <Flex direction="column" alignItems="flex-start" fullWidth>
      {renderTotals(title, delta, formattedTotal, isLoaded, tooltipText)}
    </Flex>
  )
}

export default MetricTotals
