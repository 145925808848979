import _ from 'lodash'

import { DriftMetricsAPI } from 'api'

export const sendDriftMetric = (metricQuery) =>
  DriftMetricsAPI.post('/monitoring/metrics/add', metricQuery)

export const createDimension = (value, key) => `${key}=${value}`

export const createMetric = (name, dimensions) => {
  return `${name}:${_.map(
    _.pickBy(dimensions, _.negate(_.isUndefined)),
    createDimension
  ).join(';')}`
}

export class MetricTimer {
  _startTime = undefined
  _elapsedTime = 0

  static start() {
    const timer = new MetricTimer()
    timer.start()
    return timer
  }

  start() {
    this._startTime = Date.now()
  }

  stop() {
    if (this._startTime) {
      this._elapsedTime = Date.now() - this._startTime
    }
  }

  getElapsedTime() {
    return this._elapsedTime
  }
}

export const getDateRangeDimension = (startDate, endDate) => {
  const daysDiff = endDate.diff(startDate, 'days')
  if (daysDiff <= 1) {
    return '1d'
  } else if (daysDiff <= 7) {
    return '7d'
  } else if (daysDiff > 7 && daysDiff <= 14) {
    return '14d'
  } else if (daysDiff > 14 && daysDiff <= 31) {
    return '30d'
  } else if (daysDiff > 31 && daysDiff <= 92) {
    return '90d'
  } else if (daysDiff > 92 && daysDiff <= 183) {
    return '6m'
  } else if (daysDiff > 183 && daysDiff <= 366) {
    return '1y'
  }
  return 'gt_1y'
}

export const bucketMetric = (bucketName, dimensions, value) => ({
  metric: createMetric(bucketName, {
    ...dimensions,
    app: 'app-home-web',
  }),
  value,
  dimensions: {
    type: 'bucket',
    buckets: '1000, 5000, 10000, 15000, 30000, 60000',
  },
})

export const loadTimeBucketMetric = (
  bucketName,
  chart,
  view,
  startDate,
  endDate,
  duration
) =>
  bucketMetric(
    bucketName,
    {
      chart,
      view,
      date_range: getDateRangeDimension(startDate, endDate),
    },
    duration
  )

export const chartLoadTimeBucketMetric = (
  chart,
  view,
  startDate,
  endDate,
  duration
) =>
  loadTimeBucketMetric(
    'chart.loadTime.buckets',
    chart,
    view,
    startDate,
    endDate,
    duration
  )
