import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_ZONE_DIMENSIONS,
  DRIFT_CHAT_END_USERS_DIMENSIONS,
} from 'cube'
import { Moment } from 'moment'
import * as R from 'ramda'

import { type CubeQuery, CubeQueryBuilder } from '@driftt/dds.reports'

export const formatHeatMapDrillDownQuery = (
  hourOfDay: string,
  dayOfWeek: string,
  startDate: Moment,
  endDate: Moment
): CubeQuery => {
  return new CubeQueryBuilder({})
    .withName(`ConversationHeatMapDrillDown-${hourOfDay}-${dayOfWeek}`)
    .withQueryName(`ConversationHeatMapDrillDown-${hourOfDay}-${dayOfWeek}`)
    .withDimensions([
      CONVERSATION_FACTS_ZONE_DIMENSIONS.CONVERSATION_CREATED_AT,
      CONVERSATION_FACTS_DIMENSIONS.HAS_EMAIL_CAPTURED,
      CONVERSATION_FACTS_DIMENSIONS.CQL_SCORE,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_ID,
      DRIFT_CHAT_END_USERS_DIMENSIONS.EMAIL,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_STATUS,
    ])
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      startDate.toDate(),
      endDate.toDate()
    )
    .withFilters([
      {
        member: CONVERSATION_FACTS_DIMENSIONS.HOUR_OF_DAY,
        operator: 'equals',
        values: [hourOfDay.toString()],
      },
      {
        member: CONVERSATION_FACTS_DIMENSIONS.DAY_OF_WEEK,
        operator: 'equals',
        values: [dayOfWeek],
      },
    ])
    .withOrder([
      [CONVERSATION_FACTS_ZONE_DIMENSIONS.CONVERSATION_CREATED_AT, 'desc'],
    ])
    .build()
}

/**
 * Maps day abbreviations to numbers. Used to format day values in the conversations heat map series data.
 */
export const DayToNumberMap: Record<string, number> = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
}

/**
 * Used for mapping a given number to the corresponding day string abbreviation.
 * @param day number
 * @returns string
 */
export const getDayAsString = (day: number): string => {
  return (
    Object.keys(DayToNumberMap).find((key: string) =>
      R.equals(DayToNumberMap[key], day)
    ) ?? ''
  )
}

export const militaryToStandard = (hour: number): string => {
  switch (hour) {
    case 0:
      return '12am'
    case 12:
      return '12pm'
    default:
      return hour < 12 ? `${hour}am` : `${hour - 12}pm`
  }
}
