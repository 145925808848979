export const receiveRevenueTotals = (payload) => {
  return {
    type: 'RECEIVE_REVENUE_TOTALS',
    payload,
  }
}

export const updateSalesforceConnected = (payload) => {
  return {
    type: 'UPDATE_SALESFORCE_CONNECTED',
    payload,
  }
}

export const updateRevenueOptOut = (payload) => {
  return {
    type: 'UPDATE_REVENUE_OPT_OUT',
    payload,
  }
}

export const receiveOpportunityDrilldownCreated = (payload) => {
  return {
    type: 'RECEIVE_OPPORTUNITY_DRILLDOWN_CREATED',
    payload,
  }
}

export const receiveOpportunityDrilldownClosed = (payload) => {
  return {
    type: 'RECEIVE_OPPORTUNITY_DRILLDOWN_CLOSED',
    payload,
  }
}

export const onReceiveNewDateRange = (payload) => {
  return {
    type: 'RECEIVE_NEW_DATE_RANGE',
    payload,
  }
}
