import React from 'react'

import cx from 'classnames'

import { Button } from '@driftt/dds'
import { ColorWay, H3, P } from '@driftt/tide-core'
import { Icon } from '@driftt/tide-icons'

import DiscoverHeader from './DiscoverHeader'

import './style.css'

const DiscoverHome = ({ email, name }) => {
  return (
    <div className="discover-base">
      <div
        data-private
        className={cx('discover-container', 'discover-container--top')}
      >
        <DiscoverHeader email={email} name={name} />
      </div>
      <div className={cx('discover-container', 'discover-container--bottom')}>
        <div className="discover-layout">
          <div className="discover-feed-empty-state-container">
            <div className="discover-feed-empty-state-contents">
              <ColorWay className="discover-feed-empty-state-icon">
                <Icon name="cw-empty-search" />
              </ColorWay>
              <H3>More updates coming soon!</H3>
              <P className="discover-feed-empty-state-text">
                The Drift team is currently chugging away to release our next
                series of product updates. We’ll let you know as soon as we’re
                done! In the meantime, make sure you’re up-to-date on our older
                releases and announcements.
              </P>
              <Button
                variant="secondary"
                onClick={() => window.open('https://community.drift.com')}
              >
                View all updates
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscoverHome
