import { merge } from 'lodash'

const initialState = {
  status: {},
}

const schedulingReducer = (state = initialState, action) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'RECIEVE_SCHEDULING_STATUS':
      return merge({}, state, { status: action.status })

    default:
      return state
  }
}

export default schedulingReducer
