import euConfig from './eu.json'
import prodConfig from './production.json'
import qaConfig from './qa.json'

const localConfigs = {
  QA: qaConfig,
  EU: euConfig,
  US: prodConfig,
}

export default {
  ...(window.DRIFT_CONFIG || {}),
  ...localConfigs[
    window.DRIFT_REGION || (window.__ENV__ === 'PRODUCTION' ? 'US' : 'QA')
  ],
}
