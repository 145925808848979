import type { Moment } from 'moment'

import { tracking } from '@driftt/traffic-control'

import { useDateRangeContext } from 'contexts/dateRange'

const trackDrillDownClick = (
  drillDownName: string,
  startDate: Moment,
  endDate: Moment,
  totalCount: number
) =>
  tracking.trackEvent('CUBE_DRILL_DOWN_CLICK', {
    metricType: drillDownName,
    startDate,
    endDate,
    totalCount,
    pageName: window.location.pathname,
  })

export const useTrackDrillDownModalView = (drillDownName: string) => {
  const { timePeriod } = useDateRangeContext()

  const trackViewEvent = ({ totalRecords = 0 }: { totalRecords: number }) => {
    trackDrillDownClick(
      drillDownName,
      timePeriod.startDate,
      timePeriod.endDate,
      totalRecords
    )
  }

  return {
    trackViewEvent,
  }
}
