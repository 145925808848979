import React from 'react'

import DashboardMetrics from 'containers/DashboardMetrics'

import {
  GoalPreviewCard,
  OnboardingProvider,
} from '@driftt/onboarding-components'
import { Div } from '@driftt/tide-core'

import { hasGoalsPreview } from 'utils/accessUtils'

import './style.css'

const Home = () => (
  <Div className="dashboard-page-lower">
    {hasGoalsPreview() && (
      <OnboardingProvider>
        <GoalPreviewCard className="dashboard-goal-preview" />
      </OnboardingProvider>
    )}
    <DashboardMetrics />
  </Div>
)

export default Home
