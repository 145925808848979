import React from 'react'

import MeetingMetrics from 'containers/MeetingMetrics'
import RevenueMetrics from 'containers/RevenueMetrics'
import { Moment } from 'moment'

import { Box, SimpleGrid } from '@driftt/dds'

import ConversationMetrics from './ConversationMetrics'
import { DriftOverviewMetrics } from './DriftOverviewMetrics'

type DashboardMetricsProps = {
  hasSalesforceInPlan?: boolean
  isRevenueOptedIn?: boolean
  isFreeUser?: boolean
  timePeriod?: {
    startDate: Moment
    endDate: Moment
    prevStart: Moment
    prevEnd: Moment
  }
  legendText?: string
  isLoading?: boolean
}

export const DashboardMetrics = ({
  hasSalesforceInPlan,
  isRevenueOptedIn,
  isFreeUser,
  timePeriod,
  legendText,
  isLoading,
}: DashboardMetricsProps) => {
  const shouldRenderRevenueMetrics =
    hasSalesforceInPlan && isRevenueOptedIn && !isFreeUser

  if (isLoading) {
    return null
  }

  return (
    <Box>
      <SimpleGrid minChildWidth={475} gap={2}>
        <DriftOverviewMetrics />
        {shouldRenderRevenueMetrics && (
          <RevenueMetrics timePeriod={timePeriod} legendText={legendText} />
        )}
      </SimpleGrid>
      <MeetingMetrics timePeriod={timePeriod} legendText={legendText} />
      <ConversationMetrics />
    </Box>
  )
}
