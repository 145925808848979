import React, { Component } from 'react'

import { Flex, Header, SimpleGrid } from '@driftt/dds'

import TrackableButton from 'components/TrackableButton'
import { userHasConversationsReportAccess } from 'utils/accessUtils'

import MeetingsBookedMetrics from './MeetingsBookedMetrics'
import MeetingsRepMetrics from './MeetingsRepMetrics'

import './style.css'

class MeetingMetrics extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.timePeriod !== nextProps.timePeriod ||
      this.props.isVisibleOnScreen !== nextProps.isVisibleOnScreen ||
      this.props.legendText !== nextProps.legendText
    )
  }

  render() {
    const canAccessReports = userHasConversationsReportAccess()
    const startDate = this.props.timePeriod.startDate.format('YYYY-MM-DD')
    const endDate = this.props.timePeriod.endDate.format('YYYY-MM-DD')

    return (
      <>
        <Flex
          fullWidth
          justifyContent="space-between"
          css={{ margin: '24px 0 12px 0' }}
        >
          <Header variant="h2">Meetings report</Header>
          {canAccessReports && (
            <TrackableButton
              href={`/reports/meetings/time?startDate=${startDate}&endDate=${endDate}`}
              trackingName="View Full Meetings Report"
            >
              View full meetings report
            </TrackableButton>
          )}
        </Flex>
        <SimpleGrid gap={2} minChildWidth={475}>
          <MeetingsBookedMetrics />
          <MeetingsRepMetrics />
        </SimpleGrid>
      </>
    )
  }
}

export default MeetingMetrics
