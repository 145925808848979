import React, { useState } from 'react'

import { keys as _keys, take as _take } from 'lodash'

import { Flex, SelectionTile, SimpleGrid } from '@driftt/dds'
import { getStartOfSeriesTimestamp, LineChart } from '@driftt/dds.reports'

import MetricTotals from '../MetricTotals'
import TrackableButton from '../TrackableButton'

const MetricsChart = ({
  currentMetrics,
  drilldownButton,
  drilldownLink,
  drilldownCallback,
  drilldownButtonTrackingName,
  legendText,
  previousMetrics,
  metricNames,
  selectedTab,
}) => {
  const [seletedMetric, setSelectedMetric] = useState(
    selectedTab || _keys(metricNames)[0]
  )

  const getTotalDelta = (metricName) => {
    const previousTotal =
      currentMetrics[metricName].series.length <
      previousMetrics[metricName].series.length
        ? _take(
            previousMetrics[metricName].series,
            currentMetrics[metricName].series.length
          )
            .map((x) => x[1])
            .reduce((acc, total) => acc + total)
        : previousMetrics[metricName].total

    const differenceValue = currentMetrics[metricName].total - previousTotal
    return previousTotal !== 0 ? (differenceValue / previousTotal) * 100 : 0
  }

  const seriesDataCurrent = currentMetrics[seletedMetric].series
  const seriesDataPrevious = previousMetrics[seletedMetric].series
  const pointStart = getStartOfSeriesTimestamp(seriesDataCurrent)
  return (
    <Flex direction="column" gap={3} fullHeight fullWidth>
      <SelectionTile.Group
        as={SimpleGrid}
        gap={2}
        columns={3}
        defaultValue={selectedTab}
        onChange={(metricName) => setSelectedMetric(metricName)}
        fullWidth
      >
        {_keys(metricNames).map((metricName) => {
          const timePeriodDelta = getTotalDelta(metricName)
          const data = {
            value: currentMetrics[metricName].total || 0,
            timePeriodDelta: timePeriodDelta,
          }
          return (
            <SelectionTile.Item key={metricName} id={metricName}>
              <MetricTotals
                name={metricName}
                title={metricNames[metricName].displayName}
                tooltipText={metricNames[metricName].tooltipText}
                data={data}
                isLoaded={currentMetrics[metricName].isLoaded}
              />
            </SelectionTile.Item>
          )
        })}
      </SelectionTile.Group>
      <Flex fullHeight fullWidth direction="column">
        <LineChart
          isLoading={false}
          series={[
            {
              // Spreading to avoid highcharts mutation issue
              data: [...seriesDataCurrent],
              pointStart: pointStart,
              label: legendText.series,
            },
            {
              // Spreading to avoid highcharts mutation issue
              data: [...seriesDataPrevious],
              label: legendText.previousSeries,
              pointStart: pointStart,
            },
          ]}
          hideTooltipHeader
        />
        {drilldownButton && (
          <Flex fullWidth justifyContent="flex-end">
            <TrackableButton
              onClick={drilldownCallback}
              href={drilldownLink}
              trackingName={drilldownButtonTrackingName}
            >
              {drilldownButton}
            </TrackableButton>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MetricsChart
