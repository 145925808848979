import {
  CONVERSATION_FACTS_DIMENSIONS,
  CONVERSATION_FACTS_ZONE_DIMENSIONS,
} from 'cube'
import { Moment } from 'moment'

import { type CubeQuery, CubeQueryBuilder } from '@driftt/dds.reports'

export const formatConversationsWithUrlDrillDownQuery = (
  conversationCreatedFromUrl: string,
  startDate: Moment,
  endDate: Moment
): CubeQuery => {
  return new CubeQueryBuilder({})
    .withName(
      `ConversationsStartedFromUrlDrillDown-${conversationCreatedFromUrl}`
    )
    .withQueryName(
      `ConversationsStartedFromUrlDrillDown-${conversationCreatedFromUrl}`
    )
    .withDimensions([
      CONVERSATION_FACTS_ZONE_DIMENSIONS.CONVERSATION_CREATED_AT,
      CONVERSATION_FACTS_DIMENSIONS.DURATION_BETWEEN_FIRST_INTERACTION_AND_FIRST_CLOSE_MS,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_FROM_URL,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_ID,
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_STATUS,
    ])
    .withFilters([
      {
        member: CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_FROM_URL,
        operator: 'equals',
        values: [conversationCreatedFromUrl],
      },
    ])
    .withinDateRange(
      CONVERSATION_FACTS_DIMENSIONS.CONVERSATION_CREATED_AT,
      startDate.toDate(),
      endDate.toDate()
    )
    .withOrder([
      [CONVERSATION_FACTS_ZONE_DIMENSIONS.CONVERSATION_CREATED_AT, 'desc'],
    ])
    .build()
}
