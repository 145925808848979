import React from 'react'

import { useTrackDrillDownModalView } from 'cube/hooks'
import { intComma } from 'humanize-plus'

import { Link, Table, TableColumn } from '@driftt/dds'
import { CubeQueryStringAdapter } from '@driftt/dds.reports'

import { TableWrapper } from 'components/TableWrapper'
import config from 'config/index'
import { CONVO_DETAIL_REPORT_PATH } from 'constants/reportConstants'
import { useDateRangeContext } from 'contexts/dateRange'

import { formatConversationsWithUrlDrillDownQuery } from './ConversationStartedFromTable.helpers'
import { StyledLink } from './ConversationStartedFromTable.styles'
import { ConversationStartedFromRow } from './ConversationStartedFromTable.types'
import useConversationsStartedFrom from './useConversationsStartedFrom.hook'

export const ConversationStartedFromTable = () => {
  const { tableData, isLoadingTableData } = useConversationsStartedFrom()
  const { timePeriod } = useDateRangeContext()
  const { trackViewEvent } = useTrackDrillDownModalView(
    'ConversationStartedFromTable'
  )

  const formatDrillDownURL = (conversationCreatedFromUrl: string): string => {
    const cubeQuery = formatConversationsWithUrlDrillDownQuery(
      conversationCreatedFromUrl,
      timePeriod.startDate,
      timePeriod.endDate
    )

    return `${
      config.APP_URL
    }${CONVO_DETAIL_REPORT_PATH}?${CubeQueryStringAdapter.toQueryString(
      cubeQuery
    )}`
  }

  const defaultColumns: TableColumn<ConversationStartedFromRow>[] = [
    {
      header: 'Chat URL',
      value: 'conversationCreatedFromUrl',
      cell: ({ conversationCreatedFromUrl }) => (
        <StyledLink
          variant={'inline'}
          external={true}
          href={`https://${conversationCreatedFromUrl}`}
        >
          {`https://${conversationCreatedFromUrl}`}
        </StyledLink>
      ),
      disableSort: true,
    },
    {
      header: 'Chats',
      value: 'count',
      cell: ({ count, conversationCreatedFromUrl }) => (
        <Link
          target="_blank"
          variant="inline"
          href={formatDrillDownURL(conversationCreatedFromUrl)}
          onClick={() => trackViewEvent({ totalRecords: count ?? 0 })}
        >
          {intComma(count)}
        </Link>
      ),
      disableSort: true,
    },
  ]
  return (
    <TableWrapper
      title={'Top URLs'}
      description={'What are the most common URLs chats start from?'}
    >
      <Table
        height={375}
        rows={tableData}
        columns={defaultColumns}
        isLoading={isLoadingTableData}
        loadingRows={10}
        disableBoxShadow={true}
      />
    </TableWrapper>
  )
}
