import React, { Component } from 'react'

import classnames from 'classnames'
import createHistory from 'history/createBrowserHistory'
import Cookie from 'js-cookie'
import { isEmpty as _isEmpty } from 'lodash'

import { Header } from '@driftt/dds'
import { Div, Header as TideHeader } from '@driftt/tide-core'

import { fetchSchedulingStatus } from 'api/Scheduling'
import { fetchGoogleScopesForUser } from 'api/Scopes'
import DateRangePicker from 'components/DateRangePicker'
import DriftProfileLink from 'components/DriftProfileLink'
import ReportingUserOnboardedModal from 'components/ReportingUserOnboardedModal'
import { HasSeenDashboard, HasSeenGettingStartedTab } from 'constants/Cookies'
import { unlistenPageTracking } from 'utils/Router'
import { track } from 'utils/trackingUtils'
import { getUrlParam } from 'utils/urlParamUtils'

import TabbedMarketingDashboard from '../../containers/TabbedMarketingDashboard'

import './style.css'

const TabNames = {
  dashboard: 'dashboard',
}

class HomeLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      activeTabName: TabNames.dashboard,
      unlistenPageTracking: null,
      showFirstTimeWelcomeMessage: false,
      showInviteModal: false,
      hasSeenMMModal: this.getLocalStorageVar(),
    }

    this.inviteButton = React.createRef()
    this.checkDashboardViewedCookie()
  }

  async componentDidMount() {
    const { orgId } = this.props

    await this.fetchScopes()
    await this.fetchSchedulingStatus()

    const history = createHistory()
    const pageViewTracker = unlistenPageTracking(history, orgId)

    const activeTabParam = getUrlParam('tab')
    this.setActiveTab(activeTabParam)
    this.trackGettingStartedTabView(activeTabParam)

    this.setState({ unlistenPageTracking: pageViewTracker })
    this.setLoaded()
  }

  async componentWillUnmount() {
    this.state.unlistenPageTracking && this.state.unlistenPageTracking()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.hasSeenDashboard !== prevProps.hasSeenDashboard) {
      this.setState({ showFirstTimeWelcomeMessage: true })
    }
  }

  setActiveTab = (activeTabParam) => {
    const activeTab =
      activeTabParam === TabNames.getStarted
        ? TabNames.getStarted
        : TabNames.dashboard
    this.setState({ activeTabName: activeTab })
  }

  shouldSendTrackingEvent = (activeTabParam) => {
    return (
      !!activeTabParam &&
      activeTabParam === TabNames.getStarted &&
      !Cookie.get(HasSeenGettingStartedTab)
    )
  }

  trackGettingStartedTabView = (activeTabParam) => {
    if (this.shouldSendTrackingEvent(activeTabParam)) {
      track('Getting Started Tab Viewed')
      Cookie.set(HasSeenGettingStartedTab, true)
    }
  }

  getLocalStorageVar = () => {
    const localStorageState = window.localStorage.getItem('JUNE_2019_MM_MODAL')

    return localStorageState === 'true'
  }

  checkDashboardViewedCookie = () => {
    if (!this.props.hasSeenDashboard) {
      Cookie.set(HasSeenDashboard, true)
    }
  }

  setLoaded = () => {
    this.setState({ loaded: true })
  }

  fetchScopes = () => {
    return fetchGoogleScopesForUser()
      .then((scopes) => this.props.onReceiveGoogleScopes(scopes))
      .catch((err) => console.warn('Failed to fetch scopes: ', err))
  }

  fetchSchedulingStatus = () => {
    return fetchSchedulingStatus()
      .then((status) => this.props.onRecieveSchedulingStatus(status))
      .catch((err) => console.warn('Failed to fetch scheduling status: ', err))
  }

  getWelcomeMessage = () => {
    const { activeTabName, showFirstTimeWelcomeMessage } = this.state
    if (this.isMarketingUser() && activeTabName === TabNames.getStarted) {
      return 'Getting Started with Drift'
    }

    const { orgName } = this.props
    return showFirstTimeWelcomeMessage || _isEmpty(orgName)
      ? 'Welcome'
      : `${orgName}`
  }

  selectActiveTab = (activeTabName) => {
    this.setState({ activeTabName: activeTabName })
  }

  isMarketingUser = () => {
    const { hasFullDashboardAccess, isDriftForSalesOrg, isFreeUser } =
      this.props
    return hasFullDashboardAccess && !isDriftForSalesOrg && !isFreeUser
  }

  render() {
    const { activeTabName } = this.state

    const {
      isBetaSalesRep,
      isDriftForSalesOrg,
      userHandle,
      userHandleUrl,
      timePeriod,
      onChangeTimePeriod,
    } = this.props

    return (
      <Div className="page-dashboard">
        <Div className="page-dashboard--header">
          <TideHeader
            className={classnames(
              'dashboard-center-wrapper',
              'dashboard-header',
              'dashboard-Divider',
              { 'center-flex-row': isBetaSalesRep },
              { 'dashboard-header-no-tabs-padding': !this.isMarketingUser() }
            )}
          >
            <Header variant="h1">{this.getWelcomeMessage()}</Header>
            {(isBetaSalesRep || isDriftForSalesOrg) &&
              !_isEmpty(userHandle) && (
                <Div className="dashboard-drift-handle">
                  <DriftProfileLink userHandle={userHandleUrl} />
                </Div>
              )}
            <DateRangePicker
              timePeriod={timePeriod}
              onChange={onChangeTimePeriod}
            />
          </TideHeader>
        </Div>
        <TabbedMarketingDashboard activeTabName={activeTabName} />
        <ReportingUserOnboardedModal />
      </Div>
    )
  }
}

export default HomeLayout
