import { merge } from 'ramda'

const initialState = {
  totals: {},
  salesforceConnected: false,
  isLoaded: false,
  revenueMetricsDateRanges: {
    opportunitiesCreated: [],
    opportunitiesClosed: [],
  },
}

const revenueMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_REVENUE_TOTALS':
      return merge(state, { totals: action.payload, isLoaded: true })

    case 'UPDATE_SALESFORCE_CONNECTED':
      return merge(state, { salesforceConnected: action.payload })

    case 'UPDATE_REVENUE_OPT_OUT':
      return merge(state, { hideOptIn: action.payload })

    case 'RECEIVE_OPPORTUNITY_DRILLDOWN_CREATED':
      return merge(state, { opportunitiesCreated: action.payload.results })

    case 'RECEIVE_OPPORTUNITY_DRILLDOWN_CLOSED':
      return merge(state, { opportunitiesClosed: action.payload.results })

    case 'RECEIVE_NEW_DATE_RANGE':
      return merge(state, { revenueMetricsDateRanges: action.payload })

    default:
      return state
  }
}

export default revenueMetricsReducer
