import React from 'react'

import { Box, Flex, Header, SimpleGrid } from '@driftt/dds'

import { ConversationHeatMapChart } from 'components/ConversationHeatMapChart'
import { ConversationStartedFromTable } from 'components/ConversationStartedFromTable'
import TrackableButton from 'components/TrackableButton'
import { userHasConversationsReportAccess } from 'utils/accessUtils'

const ConversationMetrics = () => {
  const canAccessReports = userHasConversationsReportAccess()

  return (
    <Box as="section" className="dashboard-center-wrapper">
      <Flex
        fullWidth
        justifyContent="space-between"
        css={{ margin: '24px 0 12px 0' }}
      >
        <Header variant="h2">Chat overview report</Header>
        {canAccessReports && (
          <TrackableButton
            href="/reports/conversations"
            trackingName="View Full Conversations Report"
          >
            View full chat overview report
          </TrackableButton>
        )}
      </Flex>
      <SimpleGrid minChildWidth={475} gap={2}>
        <ConversationStartedFromTable />
        <ConversationHeatMapChart />
      </SimpleGrid>
    </Box>
  )
}

export default ConversationMetrics
