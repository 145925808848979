import React from 'react'

import { Box, Card, Flex, Header, styled, Text } from '@driftt/dds'

import InfoIcon from '../InfoIcon'

import './style.css'

const DashboardOverviewCard = styled(Card, {
  height: '475px',
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
})

type DashboardMetricsCardProps = {
  header: string
  headerTooltip?: string
  subHeader?: string
  fullWidth?: boolean
  children: React.ReactNode
}

export const DashboardMetricsCard = ({
  header,
  subHeader,
  headerTooltip,
  fullWidth,
  children,
}: DashboardMetricsCardProps) => (
  <DashboardOverviewCard fullWidth={fullWidth}>
    <Card.Header>
      <Flex alignItems="baseline">
        <Header variant="h3">{header}</Header>
        <Box>{headerTooltip && <InfoIcon tooltipText={headerTooltip} />}</Box>
      </Flex>
      {subHeader && <Text variant="metadata">{subHeader}</Text>}
    </Card.Header>
    <Card.Content css={{ height: '90%' }}>{children}</Card.Content>
  </DashboardOverviewCard>
)
