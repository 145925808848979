import { filter, get } from 'lodash'
import { createSelector } from 'reselect'

export const orgSelector = (state) => state.org

export const hasOrgProperty = (state, property) =>
  !!orgSelector(state).properties[property]
export const getActiveNonBotUsers = (state) => {
  const users = get(orgSelector(state), 'users', [])
  return filter(users, (user) => user.status === 'ENABLED' && !user.bot)
}

export const orgCreatedAt = createSelector(orgSelector, (org) => org.createdAt)
