import React, { useCallback, useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { orgCreatedAt as orgCreatedAtSelector } from 'selectors/Org'

import { DriftDatePicker } from '@driftt/react-dates'
import { Div } from '@driftt/tide-core'

import {
  calculateTimePeriod,
  TWO_CALENDAR_YEARS_BACK,
} from 'utils/dateRangeUtils'

import './style.css'

const DateRangePicker = ({
  timePeriod,
  onChange,
  anchorDirection = 'left',
  showQuickSelects = true,
}) => {
  const [focusedInput, setFocusedInput] = useState()
  const [startDate, setStartDate] = useState(timePeriod?.startDate)
  const [endDate, setEndDate] = useState(timePeriod?.endDate)
  const orgCreatedAt = useSelector(orgCreatedAtSelector)

  const isOutsideAvailableDateRange = useCallback(
    (day) => {
      const momentDay = moment(day)
      return (
        momentDay.isAfter(moment(), 'day') ||
        momentDay.isBefore(orgCreatedAt) ||
        momentDay.isBefore(TWO_CALENDAR_YEARS_BACK)
      )
    },
    [orgCreatedAt]
  )

  const areDatesOutOfRange = useMemo(
    () =>
      isOutsideAvailableDateRange(startDate) ||
      isOutsideAvailableDateRange(endDate),
    [startDate, endDate, isOutsideAvailableDateRange]
  )

  const handleDateChange = (params) => {
    let { startDate: nextStartDate, endDate: nextEndDate } = params

    if (!nextEndDate) {
      nextEndDate = startDate
    }

    if (nextStartDate.isAfter(nextEndDate)) {
      // User has the start date in focus and selected a date beyond the end date
      nextStartDate = nextEndDate
      nextEndDate = params.startDate
    }

    if (!nextStartDate.isSame(startDate)) {
      setStartDate(nextStartDate)
    }

    if (!nextEndDate.isSame(endDate)) {
      setEndDate(nextEndDate)
    }
  }

  const onDatePickerClose = () => {
    if (
      !startDate.isSame(timePeriod?.startDate) ||
      !endDate.isSame(timePeriod?.endDate)
    ) {
      onChange(calculateTimePeriod(startDate, endDate))
    }
  }

  useEffect(() => {
    setStartDate((state) =>
      timePeriod.startDate.isSame(state) ? state : timePeriod.startDate
    )
    setEndDate((state) =>
      timePeriod.endDate.isSame(state) ? state : timePeriod.endDate
    )
  }, [timePeriod.startDate, timePeriod.endDate])

  const containerClassName = cx('date-range-picker filter-container', {
    'date-range-picker--out-of-range': areDatesOutOfRange,
  })

  return (
    <Div className={containerClassName}>
      <DriftDatePicker
        startDate={startDate}
        endDate={endDate}
        anchorDirection={anchorDirection}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        isOutsideRange={isOutsideAvailableDateRange}
        onClose={onDatePickerClose}
        showQuickSelects={showQuickSelects}
        enableOutsideDays={false}
        hideKeyboardShortcutsPanel
        readOnly
        keepFocusOnInput
      />
    </Div>
  )
}

export default DateRangePicker
