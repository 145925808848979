import {
  MEETING_FACTS_DIMENSIONS,
  MEETING_FACTS_MEASURES,
} from 'cube/constants'

import { type CubeQuery } from '@driftt/dds.reports'

/**
 * Base cube query for meeting count.
 */
export const MeetingCount: CubeQuery = {
  name: 'MeetingCount',
  queryName: 'MeetingCount',
  query: {
    measures: [MEETING_FACTS_MEASURES.MEETING_COUNT],
    order: [[MEETING_FACTS_DIMENSIONS.SCHEDULED_AT, 'desc']],
  },
}
