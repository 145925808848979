import React, { Fragment } from 'react'

import MarketingDashboard from 'containers/MarketingDashboard'

const TabbedMarketingDashboard = () => {
  return (
    <Fragment>
      <MarketingDashboard />
    </Fragment>
  )
}

export default TabbedMarketingDashboard
