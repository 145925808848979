import React, { Component } from 'react'

import produce from 'immer'
import { keys as _keys } from 'lodash'
import moment from 'moment'

import { CircleLoader } from '@driftt/tide-core'

import {
  chartLoadTimeBucketMetric,
  MetricTimer,
  sendDriftMetric,
} from 'api/DriftMetrics'
import { getIntegrationStates } from 'api/Integrations'
import { getRevenueMetrics } from 'api/Metrics'
import { patchUserProperties } from 'api/User'
import { DashboardMetricsCard } from 'components'
import MetricsChart from 'components/MetricsChart'
import {
  DefaultMetricsNoDataState,
  MetricsErrorState,
} from 'components/MetricsNoDataState'
import RevenueConnectSection from 'components/RevenueConnectSection'
import { TypeFormats } from 'utils/formatUtils'
import { logError } from 'utils/Logger'
import {
  getSeriesDataForMetrics,
  getTotalsForMetrics,
  RevenueMetricNames,
} from 'utils/metricsUtils'

const SALESFORCE_INTEGRATION_NAME = 'salesforce'
const NEVER_SHOW_REVENUE_OPT_IN = 'never-show-revenue-opt-in-home'

const REVENUE_OPT_OUT_MESSAGE =
  "We won't ask you to opt in to revenue reporting again, but you can turn it on via your Salesforce app settings"
const NO_DATA_DESCRIPTION = `
Select a different time frame to see how Drift influences your opportunities,
pipeline, and closed won.
`

const REVENUE_METRICS_HEADER_TOOLTIP =
  'Influenced by Drift means any opportunity or account that has a Drift activity on it'
const MAX_RETRIES = 3

const DEFAULT_METRIC_STATE = {
  total: null,
  series: [],
  isLoaded: false,
}

class RevenueMetrics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentMetrics: {
        opportunityCount: DEFAULT_METRIC_STATE,
        opportunityAmount: DEFAULT_METRIC_STATE,
        closedAmount: DEFAULT_METRIC_STATE,
      },
      previousMetrics: {
        opportunityCount: DEFAULT_METRIC_STATE,
        opportunityAmount: DEFAULT_METRIC_STATE,
        closedAmount: DEFAULT_METRIC_STATE,
      },
      revenueMetricsFailed: false,
      revenueMetricsLoaded: false,
      isLoadingSalesforceConnected: false,
      integrationsFailed: false,
    }
  }

  componentDidMount() {
    if (!this.props.hasSalesforceConnected) {
      this.fetchIntegrationStates()
    } else {
      this.fetchRevenueMetrics(this.props.timePeriod)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.timePeriod !== nextProps.timePeriod) {
      this.fetchRevenueMetrics(nextProps.timePeriod)
    }
  }

  fetchIntegrationStates = () => {
    const {
      timePeriod,
      showTransientNotification,
      onUpdateSalesforceConnected,
    } = this.props
    this.setState({
      integrationsFailed: false,
      isLoadingSalesforceConnected: true,
    })

    const onFetchComplete = (integrationStates) => {
      const salesforceConnected = integrationStates[SALESFORCE_INTEGRATION_NAME]

      if (salesforceConnected) {
        this.fetchRevenueMetrics(timePeriod)
      }

      this.setState(
        produce((draft) => {
          draft.isLoadingSalesforceConnected = false
          draft.hasSalesforceConnected = salesforceConnected
          draft.integrationsFailed = false

          if (!salesforceConnected) {
            draft.revenueMetricsLoaded = true
          }
        }),
        onUpdateSalesforceConnected(salesforceConnected)
      )
    }

    const onError = (err) => {
      logError(err)
      showTransientNotification({
        type: 'error',
        message: 'We had a problem getting your Salesforce connection.',
      })
      this.setState(
        produce((draft) => {
          draft.isLoadingSalesforceConnected = false
          draft.integrationsFailed = true
        })
      )
    }

    const onFetch = () =>
      getIntegrationStates().then(onFetchComplete).catch(onError)

    this.setState({ isLoadingSalesforceConnected: true }, onFetch)
  }

  fetchRevenueMetrics = async (timePeriod) => {
    const { onReceiveRevenueTotals } = this.props
    const { revenueMetricsRetryCount } = this.state
    const metricTimer = MetricTimer.start()

    const onFetchComplete = ([currentData, previousData]) => {
      metricTimer.stop()

      const currentTotals = getTotalsForMetrics(
        currentData,
        _keys(RevenueMetricNames)
      )
      const previousTotals = getTotalsForMetrics(
        previousData,
        _keys(RevenueMetricNames)
      )
      const currentSeries = getSeriesDataForMetrics(
        currentData,
        _keys(RevenueMetricNames)
      )
      const previousSeries = getSeriesDataForMetrics(
        previousData,
        _keys(RevenueMetricNames)
      )

      sendDriftMetric(
        chartLoadTimeBucketMetric(
          'revenue-influenced',
          'admin-dashboard',
          moment(timePeriod.startDate),
          moment(timePeriod.endDate),
          metricTimer.getElapsedTime()
        )
      )

      this.setState(
        produce((draft) => {
          draft.revenueMetricsLoaded = true

          Object.keys(RevenueMetricNames).forEach((metricName) => {
            draft.currentMetrics[metricName].total = currentTotals[metricName]
            draft.currentMetrics[metricName].series = currentSeries[metricName]
            draft.currentMetrics[metricName].isLoaded = true

            draft.previousMetrics[metricName].total = previousTotals[metricName]
            draft.previousMetrics[metricName].series =
              previousSeries[metricName]
            draft.previousMetrics[metricName].isLoaded = true
          })
        }),
        onReceiveRevenueTotals(currentTotals)
      )
    }

    const onError = (error) => {
      logError(error)
      if (
        !error.response &&
        (!revenueMetricsRetryCount || revenueMetricsRetryCount <= MAX_RETRIES)
      ) {
        //retry since more data will be cached in dynamo
        this.setState({
          revenueMetricsRetryCount: !revenueMetricsRetryCount
            ? 1
            : revenueMetricsRetryCount + 1,
        })
        this.fetchRevenueMetrics(timePeriod)
      } else {
        this.setState(
          produce((state) => {
            state.revenueMetricsFailed = true
            state.revenueMetricsLoaded = true
          })
        )
      }
    }

    const onFetch = () => {
      this.setState(
        produce((draft) => {
          draft.revenueMetricsLoaded = false
          draft.revenueMetricsFailed = false
        })
      )

      return Promise.all([
        getRevenueMetrics(
          TypeFormats.DATE(timePeriod.startDate),
          TypeFormats.DATE(timePeriod.endDate)
        ),
        getRevenueMetrics(
          TypeFormats.DATE(timePeriod.prevStart),
          TypeFormats.DATE(timePeriod.prevEnd)
        ),
      ])
        .then(onFetchComplete)
        .catch(onError)
    }

    this.setState(
      produce(({ currentMetrics, previousMetrics }) => {
        Object.keys(RevenueMetricNames).forEach((metricName) => {
          currentMetrics[metricName] = DEFAULT_METRIC_STATE
          previousMetrics[metricName] = DEFAULT_METRIC_STATE
        })
      }),
      onFetch
    )
  }

  updateUserProperties = async ({
    userProperties,
    successMessage,
    errorMessage,
  }) => {
    const { showTransientNotification, userId } = this.props

    const onUpdateComplete = () => {
      showTransientNotification({ type: 'success', message: successMessage })
      window.dispatchEvent(new Event('resize'))
      this.setState({ hideRevenueOptIn: true })
    }

    const onError = (err) => {
      logError(err)
      showTransientNotification({ type: 'error', message: errorMessage })
    }

    const onUpdate = () => {
      patchUserProperties(userId, userProperties)
        .then(onUpdateComplete)
        .catch(onError)
    }

    onUpdate()
  }

  onRevenueOptOut = () => {
    const { onUpdateRevenueOptOut } = this.props

    this.updateUserProperties({
      userProperties: { [NEVER_SHOW_REVENUE_OPT_IN]: true },
      successMessage: REVENUE_OPT_OUT_MESSAGE,
      errorMessage:
        'An error occurred when attempting to hide opt-in. Please try again',
    }).then(() => onUpdateRevenueOptOut(true))
  }

  hasRenderableRevenueData = () => {
    const { currentMetrics, previousMetrics } = this.state

    const metricTotals = [
      ...Object.values(currentMetrics),
      ...Object.values(previousMetrics),
    ].map((metric) => metric.total)
    return metricTotals.some((total) => total > 0)
  }

  renderContent() {
    const { hasSalesforceConnected, legendText, timePeriod } = this.props
    const {
      revenueMetricsLoaded,
      revenueMetricsFailed,
      isLoadingSalesforceConnected,
      currentMetrics,
      previousMetrics,
      integrationsFailed,
    } = this.state

    if (integrationsFailed) {
      return (
        <MetricsErrorState onRetryClick={() => this.fetchIntegrationStates()} />
      )
    }
    if (!hasSalesforceConnected && !isLoadingSalesforceConnected) {
      return <RevenueConnectSection onOptOutClick={this.onRevenueOptOut} />
    }

    if (isLoadingSalesforceConnected || !revenueMetricsLoaded) {
      return (
        <div className="dashboard-metrics-loader">
          <CircleLoader size="large" />
        </div>
      )
    } else if (revenueMetricsFailed && revenueMetricsLoaded) {
      return (
        <MetricsErrorState
          onRetryClick={() => this.fetchRevenueMetrics(timePeriod)}
        />
      )
    } else if (!this.hasRenderableRevenueData() && revenueMetricsLoaded) {
      return <DefaultMetricsNoDataState description={NO_DATA_DESCRIPTION} />
    }
    const startDate = timePeriod.startDate.format('YYYY-MM-DD')
    const endDate = timePeriod.endDate.format('YYYY-MM-DD')
    return (
      <MetricsChart
        metricNames={RevenueMetricNames}
        currentMetrics={currentMetrics}
        previousMetrics={previousMetrics}
        legendText={legendText}
        isLoading={!revenueMetricsLoaded}
        selectedTab="closedAmount"
        drilldownLink={`/reports/sales?startDate=${startDate}&endDate=${endDate}`}
        drilldownButton="View report"
        drilldownButtonTrackingName="Open Revenue Metrics Drilldown Modal"
      />
    )
  }

  render() {
    const { hideRevenueOptIn } = this.props

    if (hideRevenueOptIn) {
      return null
    }

    return (
      <DashboardMetricsCard
        header="Influenced by Drift"
        headerTooltip={REVENUE_METRICS_HEADER_TOOLTIP}
      >
        {this.renderContent()}
      </DashboardMetricsCard>
    )
  }
}

export default RevenueMetrics
