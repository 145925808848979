import React from 'react'

import cx from 'classnames'

import { Button } from '@driftt/dds'
import { H1, H3 } from '@driftt/tide-core'

import logoSrc from 'images/discover_image.jpg'

import './style.css'

const DiscoverHeader = ({ email, name }) => (
  <div className={cx('discover-layout', 'discover-layout--top')}>
    <div className="discover-header-left">
      <div className="discover-header-content">
        <div className="discover-header-titles">
          <H1 data-private className="discover-header-title">
            Welcome, {name ? name : email}!
          </H1>
          <H3 className="discover-header-subtitle">
            Discover more about Drift
          </H3>
        </div>
        <div className="discover-header-buttons">
          <Button onClick={() => window.open('https://insider.drift.com/')}>
            Go to Drift Insider
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              window.open('https://insider.drift.com/certifications')
            }
          >
            View certifications
          </Button>
        </div>
      </div>
    </div>
    <div className="discover-header-right">
      <img className="discover-header-image" src={logoSrc} alt="drift" />
    </div>
  </div>
)

export default DiscoverHeader
